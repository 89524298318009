import React, { useState, useEffect } from "react";
import { Button, Typography, Image, Checkbox, Grid, Divider } from "antd";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { removeNulls } from "../../modules/objects";
import ModalHeader from "../CommonWidgets/ModalHeader";
import Colors from "../../theme/Colors";
import { faCutlery, faExternalLink, faPlus } from "@fortawesome/pro-solid-svg-icons";

const { Title } = Typography;

const ViewRecipeModal = (props) => {
  const screen = Grid.useBreakpoint();
  const isMobile = !screen.md && !screen.lg && !screen.xl && !screen.xxl;
  const [recipeDetail, setRecipeDetail] = useState({ recipeId: props._id, ...props.recipe }),
    [checkedItems, setCheckedItems] = useState([]),
    [count, setCount] = useState(0),
    handleChange = (e, value) => {
      if (e.target.checked && !checkedItems.includes(e.target.id)) {
        setCheckedItems([...checkedItems, e.target.id]);
      } else {
        setCheckedItems(checkedItems.filter((id) => id !== e.target.id));
      }
    },
    resetCheckBoxes = () => {
      setCheckedItems([]);
      setCount(0);
    },
    selectAllCheckBoxes = () => {
      let getBoxes = document.getElementsByClassName("ant-checkbox-input");
      for (var i = 0; i < getBoxes.length; i++) {
        console.log("getBoxes", getBoxes[i].checked);
        if (getBoxes[i].checked !== true) {
          getBoxes[i].checked = true;

          checkedItems.push(getBoxes[i].id);
        }
      }
      setCount(checkedItems.length);
    },
    getPrepTime = (prepTime) => {
      if (prepTime) {
        removeNulls(prepTime);
        return prepTime && typeof prepTime !== "string" ? prepTime[Object.keys(prepTime)] + Object.keys(prepTime) : prepTime;
      } else {
        return "";
      }
    },
    getServing = (serving) => {
      return !serving ? "" : `${serving} serving${serving > 1 ? "s" : ""}`;
    },
    getFinalName = (ingItem) => {
      return !ingItem?.amt ? ingItem.name : ingItem?.amt + " " + ingItem.name;
    };

  useEffect(() => {
    setCount(checkedItems.length);
  }, [checkedItems]);

  return (
    <>
      <div className="flex flex-column" style={{ height: isMobile ? "100vh" : "90vh" }}>
        {isMobile && (
          <div className="pl4 pr4">
            <ModalHeader
              close={() => {
                props.close();
              }}
            />
          </div>
        )}
        <div className={`flex ${isMobile && "flex-column"} flex-1`}>
          <div className={`flex ${isMobile ? "" : "flex-1"}`} style={{ position: "relative" }}>
            <Image preview={false} alt="product-image" rootClassName="viewRecipeImage" style={{ padding: isMobile ? "5px" : undefined }} src={recipeDetail?.itemImage} />
            <Typography.Link href={recipeDetail.pinterestUrl} target="_blank" type="primary" className="p2" style={{ position: "absolute", top: 20, left: 20, backgroundColor: Colors.darkText, display: "flex", alignItems: "center", borderRadius: "5px" }}>
              <Typography.Text style={{ fontWeight: 600, fontSize: "15px", color: Colors.whiteText }}>See full recipe</Typography.Text>
              <FontAwesomeIcon className="ml2" icon={faExternalLink} style={{ color: Colors.primaryColor }} />
            </Typography.Link>
          </div>
          <div className="flex flex-column flex-1 pr4 pl4">
            {!isMobile && (
              <ModalHeader
                close={() => {
                  props.close();
                }}
              />
            )}

            <Typography.Title level={3} className={`${isMobile ? "mt2 mb0" : "m0"}`} style={{ fontWeight: "bold" }}>
              {recipeDetail && recipeDetail?.title}
            </Typography.Title>
            <div className="flex" style={{ columnGap: "12px", marginTop: "3px" }}>
              <Typography.Text style={{ color: Colors.mutedText, display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon className="mr1" icon={faClock} style={{ color: Colors.primaryColor, fontSize: "20px" }} /> {getPrepTime(recipeDetail?.prepTime)}
              </Typography.Text>
              <Typography.Text style={{ color: Colors.mutedText, display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon className="mr1" icon={faCutlery} style={{ color: Colors.primaryColor, fontSize: "20px" }} /> {getServing(recipeDetail?.serveCount)}
              </Typography.Text>
            </div>
            <Divider className="mt4 mb0" plain style={{ borderColor: "#c3e6cd" }} />
            <div className="flex flex-column" style={{ overflowY: "auto", flex: "1 0 0" }}>
              {recipeDetail.categorizedIngredients &&
                [...recipeDetail.categorizedIngredients].map((item, index) => {
                  return (
                    <div key={index} className="flex flex-column">
                      <Title level={5} className="mb1 mt2">
                        {item.category}
                      </Title>
                      <div>
                        {item.ingredients &&
                          item.ingredients.map((ingItem, ind) => (
                            <div key={ind}>
                              <Checkbox className="search-recipe checked-recipes" style={{ color: Colors.darkText, fontWeight: 300 }} key={ingItem.id} id={ingItem._id} value={ingItem._id} name={ingItem._id} onChange={handleChange} checked={checkedItems.includes(ingItem._id) ? "checked" : false}>
                                {getFinalName(ingItem)}
                              </Checkbox>
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`flex ${isMobile ? "pt3 pb3" : "pt5 pb5"} justify-content-end`} style={{ backgroundColor: Colors.secondaryColor }}>
          <div className="pr5 flex  align-items-center justify-content-end" style={{ columnGap: "24px" }}>
            <Typography.Text
              className="cursor-pointer"
              style={{ fontWeight: "bold", fontSize: "15px" }}
              onClick={() => {
                resetCheckBoxes();
              }}
            >
              Clear
            </Typography.Text>
            <Typography.Text
              className="cursor-pointer"
              style={{ fontWeight: "bold", fontSize: "15px" }}
              onClick={() => {
                selectAllCheckBoxes();
              }}
            >
              Select all
            </Typography.Text>
            <Button
              type="primary"
              disabled={checkedItems?.length === 0}
              style={{ minWidth: "80px" }}
              onClick={() => {
                props?.onAddToGroceryList(recipeDetail, checkedItems);
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Add to List
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewRecipeModal;
