import React, { useState } from "react";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import ModalContainer from "../../../CommonWidgets/ModalContainer";
import ModalHeader from "../../../CommonWidgets/ModalHeader";
import EmailLoginForm from "../EmailLoginForm";
import RegisterForm from "../../Register/RegisterForm";
import ForgotPasswordForm from "../../../ForgotPasswordForm";
import GoogleLoginForm from "../GoogleLoginForm";
import Logo from "../../../CommonWidgets/Logo";
import FacebookLoginForm from "../FacebookLoginForm";
import { Typography, Space, Divider } from "antd";
import "./index.scss";
import Colors from "../../../../theme/Colors";
const { Text, Title } = Typography;

const LoginModal = (props) => {
  const [currentStep, setCurrentStep] = useState("login"),
    formatHeader = currentStep.replace(/[^A-Z0-9]+/gi, " ");

  return (
    props.open && (
      <ModalContainer isOpen={props.open} title={undefined} footer={null} width={600}>
        <ModalHeader
          close={() => {
            props.close();
          }}
        />
        <div className="text-center">
          <Logo renderOriginal style={{ height: "40px" }} />
        </div>

        <Typography.Title level={3} className="text-center m0 mt5" style={{ fontWeight: "bolder" }}>
          {currentStep == "login" ? "Login" : currentStep == "forgot-password" ? "Forgot Password" : "Sign up"}
        </Typography.Title>

        <div className="mt6 login-options">
          {currentStep !== "forgot-password" && (
            <>
              <div className="social-options">
                <div className="flex">
                  <FacebookLoginForm pin={props.pin} />
                </div>
                <div className="flex">
                  <GoogleLoginForm pin={props.pin} />
                </div>
              </div>
              <Divider className="mt8 mb8" plain style={{ borderColor: "#c3e6cd" }}>
                <Typography.Text style={{ color: Colors.mutedText, fontSize: "11px" }}>Or {currentStep == "login" ? "login" : "sign up"} with your email</Typography.Text>
              </Divider>
            </>
          )}
          {currentStep === "login" && (
            <div>
              <EmailLoginForm pin={props.pin} setStep={(step) => setCurrentStep(step)} />
            </div>
          )}
          {currentStep === "register" && <RegisterForm pin={props.pin} setStep={(step) => setCurrentStep(step)} />}
          {currentStep === "forgot-password" && <ForgotPasswordForm setStep={(step) => setCurrentStep(step)} />}
        </div>
      </ModalContainer>
    )
  );
};

export default LoginModal;
