import React from "react";
import { Input, Typography } from "antd";

const LabelInput = (props) => {
  const { id, label, type, required, name, placeholder, value, onChange, disabled, inputRef, onKeyDown, style, inputStyle } = props;

  return (
    <div className="flex flex-column" style={style}>
      {label && typeof label === "string" ? <Typography.Text style={{ fontSize: "12px", fontWeight: 500 }}>{label}</Typography.Text> : <>{label}</>}
      <Input id={id} ref={inputRef} placeholder={placeholder} className="mt1 pt0" type={type} required={required} name={name} value={value} onChange={onChange} onKeyDown={onKeyDown} disabled={disabled} style={inputStyle} />
    </div>
  );
};

export default LabelInput;
