import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Typography, Button, Image } from "antd";
import { faBars, faRightFromBracket, faCutlery, faShoppingCart, faAngleDown, faAngleUp, faEdit, faTimes, faSignIn } from "@fortawesome/pro-solid-svg-icons";
import { callLogout, checkIfUserLoggedIn } from "../../helper";
import Logo from "../CommonWidgets/Logo";
import CheckWeb from "../CommonWidgets/CheckWeb";
import Home from "../../pages/Home";
import GroceryList from "../../pages/GroceryList";
import SavedRecipes from "../../pages/SavedRecipes";
import ViewRecipe from "../../pages/ViewRecipe";
import LoginModal from "../Auth/Login/LoginModal";
import ProfileModal from "../ProfileModal";
import SetPassword from "../SetPassword";
import KrogerSuccess from "../KrogerSuccess";
import { getLocalStorageDataForUser } from "../../helper";
import { handleRedirect } from "../../modules/handle-redirect";
import "./index.scss";
import Colors from "../../theme/Colors";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { CONFIG } from "../../config/config";
import { validateExtension } from "../../modules/extension";

const NavMenu = (props) => {
  const previousPluginStatus = window.localStorage.getItem("plugin-status");
  const [showLoginModal, setShowLoginModal] = useState(false),
    [showProfileModal, setShowProfileModal] = useState(false),
    [isMenuOpen, setIsMenuOpen] = useState(false),
    [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false),
    history = useHistory(),
    web = CheckWeb(),
    [pluginStatus, setPluginStatus] = useState(previousPluginStatus),
    logoItem = [
      {
        label: "",
        key: "logo",
        className: "p0",
        icon: (
          <>
            <Logo />
            <Link to="/" />
          </>
        ),
      },
    ],
    leftMenuItems = [
      {
        label: web ? "Saved Recipes" : "",
        key: "savedRecipes",
        icon: (
          <div>
            <FontAwesomeIcon icon={faCutlery} style={{ color: Colors.whiteText }} />
            <Link to="/saved-recipes" />
          </div>
        ),
      },
      {
        label: web ? "Grocery List" : "",
        key: "groceryList",
        icon: (
          <div>
            <FontAwesomeIcon icon={faShoppingCart} style={{ color: Colors.whiteText }} />
            <Link to="/grocery-list" />
          </div>
        ),
      },
    ],
    profileMenuItems = checkIfUserLoggedIn()
      ? [
          {
            label: web ? "Edit Profile" : "",
            key: "profile",
            icon: (
              <div className="pt4 pb4">
                <FontAwesomeIcon icon={faEdit} style={{ color: Colors.primaryColor }} />
                <Link
                  to="#"
                  onClick={() => {
                    setShowProfileModal(true);
                  }}
                />
              </div>
            ),
          },
          {
            label: web ? "Logout" : "",
            key: "logout",
            icon: (
              <div className="pt4 pb4">
                <FontAwesomeIcon icon={faRightFromBracket} style={{ color: Colors.primaryColor }} />
                <Link
                  to="#"
                  onClick={() => {
                    callLogout();
                  }}
                />
              </div>
            ),
          },
        ]
      : [],
    loginItem = !checkIfUserLoggedIn()
      ? [
          {
            label: web ? "Login" : "",
            key: "login",
            className: `right-menu ${web ? " p0 " : ""}`,
            icon: (
              <>
                <FontAwesomeIcon icon={faSignIn} style={{ color: Colors.whiteText }} />
                <Link
                  to="#"
                  onClick={() => {
                    setShowLoginModal(true);
                  }}
                />
              </>
            ),
          },
        ]
      : [],
    mobileLoginItems = [
      {
        label: "",
        key: "authentication",
        icon: (
          <div className="flex flex-column">
            <div
              className={`flex w-full justify-content-between align-items-center`}
              style={{ backgroundColor: "#FFFFFF0D", padding: "25px 15px" }}
              onClick={(e) => {
                if (checkIfUserLoggedIn()) {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsProfileMenuOpen((isOpen) => !isOpen);
                }
              }}
            >
              <Typography.Text style={{ color: Colors.whiteText, fontWeight: "bold" }}>{checkIfUserLoggedIn() ? `Hi, ${getLocalStorageDataForUser().firstName}` : "Login"}</Typography.Text>
              <FontAwesomeIcon icon={!checkIfUserLoggedIn() ? faSignIn : isProfileMenuOpen ? faAngleUp : faAngleDown} style={{ color: Colors.whiteText }} />
            </div>
            {isProfileMenuOpen && (
              <div className="flex flex-column w-full" style={{ backgroundColor: "#FFFFFF0D" }}>
                <div className="w-full" style={{ height: "0.1px", borderTop: `0.5px solid ${Colors.primaryColor}`, opacity: 0.5 }} />
                <div
                  style={{ padding: "0px 15px" }}
                  onClick={() => {
                    setShowProfileModal(true);
                  }}
                >
                  <Typography.Text className="flex w-full justify-content-start align-items-center" style={{ color: Colors.whiteText, padding: "15px 0px 7px 0px", columnGap: "5px" }}>
                    <FontAwesomeIcon icon={faEdit} style={{ color: Colors.primaryColor, fontSize: "15px", width: "20px" }} />
                    Edit Profile
                  </Typography.Text>
                </div>
                <div
                  style={{ padding: "7px 15px 7px 15px" }}
                  onClick={() => {
                    callLogout();
                  }}
                >
                  <Typography.Text className="flex w-full justify-content-start align-items-center" style={{ color: Colors.whiteText, padding: "7px 0px 7px 0px", columnGap: "5px" }}>
                    <FontAwesomeIcon icon={faRightFromBracket} style={{ color: Colors.primaryColor, fontSize: "15px", width: "20px" }} />
                    Logout
                  </Typography.Text>
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        label: "",
        key: "mobileHomeItem",
        icon: (
          <>
            <Link to="/" className="flex w-full justify-content-start align-items-center" style={{ padding: "25px 0px", columnGap: "5px" }}>
              <FontAwesomeIcon icon={faHome} style={{ fontSize: "18px", width: "25px" }} />
              Home
            </Link>
          </>
        ),
      },
      ...(checkIfUserLoggedIn()
        ? [
            {
              label: "",
              key: "mobileSavedRecipesItem",
              icon: (
                <>
                  <div className="w-full" style={{ height: "0.1px", borderTop: `0.5px solid ${Colors.primaryColor}`, opacity: 0.5 }} />
                  <Link to="/saved-recipes" className="flex w-full justify-content-start align-items-center" style={{ padding: "25px 0px 12px 0px", columnGap: "5px" }}>
                    <FontAwesomeIcon icon={faCutlery} style={{ fontSize: "18px", width: "25px" }} />
                    Saved Recipes
                  </Link>
                </>
              ),
            },
            {
              label: "",
              key: "mobileGroceryListsItem",
              icon: (
                <>
                  <Link to="/grocery-list" className="flex w-full justify-content-start align-items-center" style={{ padding: "12px 0px 12px 0px", columnGap: "5px" }}>
                    <FontAwesomeIcon icon={faShoppingCart} style={{ fontSize: "18px", width: "25px" }} />
                    Grocery Lists
                  </Link>
                </>
              ),
            },
          ]
        : []),
      ...(pluginStatus != 1
        ? [
            {
              label: "",
              key: "mobileDownloadChromeItem",
              icon: (
                <div className="flex flex-column">
                  <div className="w-full" style={{ height: "0.1px", borderTop: `0.5px solid ${Colors.primaryColor}`, opacity: 0.5 }} />
                  <Button
                    className="mt8 align-self-start flex align-items-center"
                    type="primary"
                    style={{ height: "40px", paddingLeft: "20px", paddingRight: "20px", color: Colors.darkText, backgroundColor: Colors.whiteText, borderRadius: "5px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.location.href = "https://chrome.google.com/webstore/detail/gatherd/" + CONFIG.GOOGLE_CHROME_EXTENSION_ID;
                    }}
                  >
                    <Image src="img/brand/chromeIcon128.png" preview={false} alt="Chrome Icon" style={{ objectFit: "contain", height: "25px" }} />
                    <Typography.Text className="ml2" style={{ color: Colors.darkText, fontWeight: "bold", fontSize: "15px" }}>
                      Download
                    </Typography.Text>
                  </Button>
                </div>
              ),
            },
          ]
        : []),
    ],
    rightWebMenuItems = [
      ...loginItem,
      checkIfUserLoggedIn() && {
        label: "",
        className: "right-menu p0",
        key: "myAccount",
        icon: (
          <div>
            {pluginStatus != 1 && (
              <Button
                className="align-self-start flex align-items-center"
                type="primary"
                style={{ height: "40px", paddingLeft: "20px", paddingRight: "20px", color: Colors.darkText, backgroundColor: Colors.whiteText, borderRadius: "5px" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.location.href = "https://chrome.google.com/webstore/detail/gatherd/" + CONFIG.GOOGLE_CHROME_EXTENSION_ID;
                }}
              >
                <Image src="img/brand/chromeIcon128.png" preview={false} alt="Chrome Icon" style={{ objectFit: "contain", height: "25px" }} />
                <Typography.Text className="ml2" style={{ color: Colors.darkText, fontWeight: "bold", fontSize: "15px" }}>
                  Download
                </Typography.Text>
              </Button>
            )}
            <Typography.Text className="ml4" style={{ color: Colors.whiteText, fontWeight: "600", fontSize: "16px" }}>
              Hi, {getLocalStorageDataForUser().firstName} <FontAwesomeIcon icon={faAngleDown} style={{}} />
            </Typography.Text>
          </div>
        ),
        children: [...profileMenuItems],
      },
    ],
    rightMobileMenuItems = [
      {
        label: "",
        key: "myAccount",
        icon: (
          <div className="bars-icon-wrapper">
            <FontAwesomeIcon icon={!isMenuOpen ? faBars : faTimes} style={{ color: Colors.whiteText, fontSize: "20px" }} />
          </div>
        ),
        children: [...mobileLoginItems],
      },
    ],
    storedMenuItem = window.localStorage.getItem("currentkey"),
    [current, setCurrent] = useState(storedMenuItem ? storedMenuItem : "logo");
  useEffect(() => {
    validateExtension((ok) => {
      setPluginStatus(ok);
    });
  }, []);

  const onClick = (e) => {
      console.log("click ", e);
      if (e.key === "savedRecipes" || "groceryList") window.localStorage.setItem("currentkey", e.key);
      if (!checkIfUserLoggedIn()) {
        setShowLoginModal(true);
      } else {
        setCurrent(e.key);

        /* switch (e.key) {
          case "savedRecipes":
            history.push("/saved-recipes");
          break;
          case "groceryList":
            history.push("/grocery-list");
          break;
          default:
          history.push("/");
          break;
        } */
        handleRedirect(history, "push");
      }
    },
    getWebMenu = () => {
      if (web) {
        console.log([...logoItem, ...(checkIfUserLoggedIn() ? leftMenuItems : []), ...rightWebMenuItems]);
        return [...logoItem, ...(checkIfUserLoggedIn() ? leftMenuItems : []), ...rightWebMenuItems];
      } else {
        return [...logoItem, ...rightMobileMenuItems];
      }
    };

  return (
    <>
      <Router>
        <div className="menu-bg app-container noselect" style={{ display: "flex", justifyContent: "center" }}>
          <Menu
            className="container w-full"
            style={{ display: "flex", justifyContent: "space-between" }}
            theme="dark"
            onClick={(e) => onClick(e)}
            triggerSubMenuAction="click"
            selectedKeys={[current]}
            mode="horizontal"
            items={getWebMenu()}
            onOpenChange={() => {
              if (isMenuOpen) {
                setIsMenuOpen(false);
                setIsProfileMenuOpen(false);
                document.body.style.overflow = "auto";
              } else {
                setIsMenuOpen(true);
                window.scrollTo(0, 0);
                document.body.style.overflow = "hidden";
              }
            }}
          />
        </div>

        <div className={`app-container noselect`} style={{ flex: "1 1", display: "flex", justifyContent: "center" }}>
          <div className={"page-wrapper container"}>
            {/* Routes that should swap w/ auth */}
            <Route exact path="/" component={Home} />
            <Route exact path="/krogercallback" component={KrogerSuccess} />
            <Route path="/saved-recipes" component={SavedRecipes} menuItem="savedRecipes" />
            <Route path="/grocery-list" component={GroceryList} menuItem="groceryList" />
            <Route path="/recipe-detail" component={ViewRecipe} menuItem="viewRecipe" />
            <Route exact path="/reset-password" component={SetPassword} />
          </div>
        </div>
      </Router>
      {showLoginModal && <LoginModal open={true} close={() => setShowLoginModal(false)} />}
      {showProfileModal && <ProfileModal open={showProfileModal} close={() => setShowProfileModal(false)} viewProfile={1} />}
    </>
  );
};
export default NavMenu;
