import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { forgotPasswordRequest } from "../../redux/actions/forgot-password";
import { notification, Card, Typography, Button, Form, Space } from "antd";
import FloatInput from "../CommonWidgets/FloatInput";
import { RightArrow } from "../CommonWidgets/FontIcons";
import LabelInput from "../CommonWidgets/LabelInput";
import Colors from "../../theme/Colors";
const { Title, Text, Link } = Typography;

const ForgotPasswordForm = (props) => {
  const { forgotPasswordData } = useSelector((state) => ({ forgotPasswordData: state.forgotPasswordData })),
    [isLoading, setLoading] = useState(false),
    dispatch = useDispatch(),
    [form] = Form.useForm(),
    [hasForgotPasswordFormValue, setForgotPasswordFormValue] = useState({
      email: "",
    }),
    onChange = (e) => {
      let formData = { ...hasForgotPasswordFormValue };
      setForgotPasswordFormValue({ ...formData, [e.target.name]: e.target.value });
    },
    onForgotPassword = (data) => {
      setLoading(true);
      dispatch(forgotPasswordRequest(data.user));
    };

  useEffect(() => {
    //console.log("forgotPasswordData state changed => ", forgotPasswordData);
    if (!forgotPasswordData.error && Object.keys(forgotPasswordData?.payload).length) {
      notification.success({ message: "Success", description: forgotPasswordData?.payload?.message, placement: "topRight" });
      setLoading(false);
    } else {
      //console.log("forgotPasswordData error", forgotPasswordData?.error?.message);
      if (forgotPasswordData?.error?.message) {
        setLoading(false);
        notification.error({ message: "Error", description: forgotPasswordData?.error?.message, placement: "topRight" });
      }
    }
  }, [forgotPasswordData]);

  return (
    <Form
      form={form}
      className="pb4"
      name="forgot_password"
      initialValues={{
        remember: true,
      }}
      onFinish={onForgotPassword}
    >
      <Space direction="vertical" className="w-full">
        <Typography.Text className="flex w-full text-center" style={{ color: Colors.mutedText }}>
          Please enter your email below and we will send you instructions to reset your password
        </Typography.Text>
        <Form.Item
          className="mt4"
          name={["user", "email"]}
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail",
            },
            {
              required: true,
              message: "Please input your Email",
            },
          ]}
          hasFeedback
        >
          <LabelInput label="Email" type="text" placeholder="johndoe@example.com" required={true} name="email" value={hasForgotPasswordFormValue.email} onChange={(e) => onChange(e)} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button type="primary" block htmlType="submit" loading={isLoading} disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}>
              <span className="f18">Submit</span>
            </Button>
          )}
        </Form.Item>
      </Space>
      <Form.Item className="text-center">
        <span>
          <Text>Already have an account?</Text>
          <Link className="ml1" onClick={() => props.setStep("login")}>
            Login here
          </Link>
        </span>
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;
