import React, { useState, useEffect } from "react";
import { notification, Button, Typography, Image, Checkbox, Grid, Divider, Input, Select } from "antd";
import "./index.scss";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { removeNulls } from "../../modules/objects";
import ModalHeader from "../../components/CommonWidgets/ModalHeader";
import Colors from "../../theme/Colors";
import { createGroceryList } from "../../modules/ajax/create-grocery";
import { formatTodayDate, formatGroceryList } from "../../helper";
import { useSelector, useDispatch } from "react-redux";
import { groceryListRequest } from "../../redux/actions/grocery";
import { createGroceryListRequest, createGroceryListReset } from "../../redux/actions/create-grocery";
import { faCutlery, faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import ShowSelectedItems from "../ShowSelectedItems";

const { Title } = Typography;

const CreateGroceryListModal = (props) => {
  const dispatch = useDispatch();
  const screen = Grid.useBreakpoint();
  const isMobile = !screen.md && !screen.lg && !screen.xl && !screen.xxl;
  const [recipeDetail, setRecipeDetail] = useState(props?.recipeDetail),
    [masterGroceryList, setMasterGroceryList] = useState([]),
    [groceryListSearchValue, setGroceryListSearchValue] = useState(),
    { createGroceryData, groceryData } = useSelector((state) => ({ createGroceryData: state.createGroceryData, groceryData: state.groceryData })),
    [checkedItems, setCheckedItems] = useState([]),
    [newListName, setNewListName] = useState(formatTodayDate()),
    [selectedExistingList, setSelectedExistingList] = useState(),
    getPrepTime = (prepTime) => {
      if (prepTime) {
        removeNulls(prepTime);
        return prepTime && typeof prepTime !== "string" ? prepTime[Object.keys(prepTime)] + Object.keys(prepTime) : prepTime;
      } else {
        return "";
      }
    },
    getServing = (serving) => {
      return !serving ? "" : `${serving} serving`;
    },
    getFinalName = (ingItem) => {
      return !ingItem?.amt ? ingItem.name : ingItem?.amt + " " + ingItem.name;
    };

  useEffect(() => {
    dispatch(groceryListRequest());
  }, []);

  useEffect(() => {
    if (!createGroceryData.error && Object.keys(createGroceryData?.payload).length) {
      window.localStorage.setItem("groceryId", createGroceryData?.payload?.data._id);
      notification.success({ message: "Success", description: createGroceryData?.payload?.message, placement: "topRight" });
      props?.resetCartItems && typeof props.resetCartItems === "function" && props.resetCartItems();
      dispatch(createGroceryListReset());
    } else {
      if (createGroceryData?.error?.message) notification.error({ message: "Error", description: createGroceryData?.error?.message, placement: "topRight" });
    }
  }, [createGroceryData]);
  console.log("selectedListName", selectedExistingList, "new ListName", newListName);
  useEffect(() => {
    if (!groceryData.error && Object.keys(groceryData?.payload).length) {
      setMasterGroceryList(groceryData?.payload?.data);
    } else {
      // if (groceryData?.error?.message) notification.error({ message: "Error", description: groceryData?.error?.message, placement: "topRight" });
      setMasterGroceryList([]);
    }
  }, [groceryData]);

  return (
    <>
      <div className="flex flex-column" style={{ height: isMobile ? "100vh" : "90vh" }}>
        {isMobile && (
          <div className="pl4 pr4">
            <ModalHeader
              close={() => {
                props.close();
              }}
            />
          </div>
        )}
        <div className={`flex ${isMobile && "flex-column"} flex-1`}>
          <div className={`flex ${isMobile ? "" : "flex-1"}`} style={{ position: "relative" }}>
            <Image preview={false} alt="product-image" rootClassName="viewRecipeImage" style={{ padding: isMobile ? "5px" : undefined }} src={recipeDetail?.itemImage} />
            <Typography.Link href={recipeDetail.pinterestUrl} target="_blank" type="primary" className="p2" style={{ position: "absolute", top: 20, left: 20, backgroundColor: Colors.darkText, display: "flex", alignItems: "center", borderRadius: "5px" }}>
              <Typography.Text style={{ fontWeight: 600, fontSize: "15px", color: Colors.whiteText }}>See full recipe</Typography.Text>
              <FontAwesomeIcon className="ml2" icon={faExternalLink} style={{ color: Colors.primaryColor }} />
            </Typography.Link>
          </div>

          <div className="flex flex-column flex-1 pr4 pl4">
            {!isMobile && (
              <ModalHeader
                close={() => {
                  props.close();
                }}
              />
            )}

            <Typography.Title level={3} className={`${isMobile ? "mt2 mb0" : "m0"}`} style={{ fontWeight: "bold" }}>
              {recipeDetail && recipeDetail?.title}
            </Typography.Title>
            <div className="flex" style={{ columnGap: "12px", marginTop: "3px" }}>
              <Typography.Text style={{ color: Colors.mutedText, display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon className="mr1" icon={faClock} style={{ color: Colors.primaryColor, fontSize: "20px" }} /> {getPrepTime(recipeDetail?.prepTime)}
              </Typography.Text>
              <Typography.Text style={{ color: Colors.mutedText, display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon className="mr1" icon={faCutlery} style={{ color: Colors.primaryColor, fontSize: "20px" }} /> {getServing(recipeDetail?.serveCount)}
              </Typography.Text>
            </div>
            <Divider className="mt4 mb0" plain style={{ borderColor: "#c3e6cd" }} />
            <Typography.Text className="mt4" style={{ fontWeight: 600, fontSize: "15px" }}>
              Adding {props?.selectedItems?.items?.length} Items to Grocery List
            </Typography.Text>
            <div className="mt2 flex flex-column">
              <Divider className="m0" plain style={{ borderColor: "#c3e6cd" }} />
              <div className="flex flex-column" style={{ overflowY: "auto", maxHeight: "200px" }}>
                <ShowSelectedItems listItems={props?.selectedItems} />
              </div>
              <Divider className="m0" plain style={{ borderColor: "#c3e6cd" }} />
            </div>
            <div className="flex flex-column mt8">
              <Typography.Text style={{ fontWeight: 600, fontSize: "15px" }}>Add to an existing list</Typography.Text>
              <Select
                showSearch
                showArrow={true}
                placeholder="Select list"
                optionLabelProp="label"
                className="w-full existing-list-items mt2"
                popupClassName="existing-list-items-popup"
                onSelect={(newValue) => {
                  if (newValue === groceryListSearchValue) {
                    setNewListName(newValue);
                    setSelectedExistingList();
                  } else {
                    setSelectedExistingList(newValue);
                    setNewListName();
                  }
                }}
                searchValue={groceryListSearchValue}
                onSearch={(searchValue) => {
                  setGroceryListSearchValue(searchValue);
                }}
                filterOption={(input, option) => (option?.title ?? "").includes(input)}
                value={selectedExistingList || newListName}
              >
                {groceryListSearchValue && groceryListSearchValue.length > 0 && (
                  <Select.Option
                    key={`groceryListItemNotExist`}
                    value={groceryListSearchValue}
                    title={groceryListSearchValue}
                    label={
                      <>
                        <div>{groceryListSearchValue}</div>
                      </>
                    }
                  >
                    <div>
                      Create <strong>"{groceryListSearchValue}"</strong>
                    </div>
                  </Select.Option>
                )}
                {(masterGroceryList || []).map((inputValue, index) => {
                  return (
                    <Select.Option
                      key={`${index}groceryListItem`}
                      value={inputValue._id}
                      title={inputValue.title}
                      label={
                        <>
                          <div>{inputValue.title}</div>
                        </>
                      }
                    >
                      <div>{inputValue.title}</div>
                      <div>{moment(inputValue?.createdAt).format("YYYY/MM/DD - hh:mm")}</div>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
        <div className={`flex pt5 pb5 justify-content-end`} style={{ backgroundColor: Colors.secondaryColor }}>
          <div className="pr5 flex align-items-center justify-content-end" style={{ columnGap: "24px" }}>
            <Button
              type="primary"
              disabled={!newListName && !selectedExistingList}
              style={{ minWidth: "80px" }}
              onClick={() => {
                let finalData = { items: props?.selectedItems.items, recipeDetail };
                if (selectedExistingList) {
                  let newList = {};
                  newList.key = selectedExistingList;
                  const getTheLabel = masterGroceryList.filter((row) => row?._id === selectedExistingList);
                  newList.title = getTheLabel[0]?.title;
                  let trimmedData = finalData.items.filter((ele, ind) => ind === finalData.items.findIndex((elem) => elem._id === ele._id));
                  newList.items = formatGroceryList(trimmedData, 0);

                  finalData = { ...finalData, ...newList };
                } else if (newListName) {
                  let uniqueData = finalData.items.filter((ele, ind) => ind === finalData.items?.findIndex((elem) => elem._id === ele._id));
                  let trimData;

                  if (uniqueData?.length) {
                    trimData = formatGroceryList(uniqueData, 0);
                  }
                  let newList = {};
                  newList.key = Math.random();
                  newList.items = trimData;
                  newList.title = newListName || formatTodayDate();
                  finalData = { ...finalData, ...newList };
                }

                createGroceryList(finalData)
                  .then((res) => {
                    if (res?.error === false) {
                      window.localStorage.setItem("groceryId", res?.data._id);
                      notification.success({ message: "Success", description: res?.message, placement: "topRight" });

                      dispatch(groceryListRequest());

                      props?.close();
                      window.location.href = "/grocery-list";
                    } else {
                      notification.error({ message: "Success", description: res?.message, placement: "topRight" });
                    }
                  })
                  .catch((err) => {
                    notification.error({ message: "Success", description: err?.message, placement: "topRight" });
                  });
              }}
            >
              {newListName && newListName.length > 0 ? "Create" : "Add to List"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateGroceryListModal;
