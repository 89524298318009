import { Grid } from "antd";
const { useBreakpoint } = Grid;

const CheckWeb = () => {
  const screens = useBreakpoint();

  let findRes = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .map((screen) => screen[0]),
    web = ["md", "lg", "xl", "xxl"];
    console.log(screens, findRes);
  const ifWeb = web.some((res) => findRes.includes(res));
  
  return ifWeb;
};

export default CheckWeb;
