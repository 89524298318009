import React, { useState } from "react";
import NotLoggedIn from "../NotLoggedIn";
import { checkIfUserLoggedIn } from "../../helper";
import { Form, Input, Typography } from "antd";
import FloatInput from "../CommonWidgets/FloatInput";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
const { Title } = Typography;

const SearchTextBlock = (props) => {
  const [mixText, setMixText] = useState(""),
    placeholderText = props?.placeholderText,
    labelText = props?.labelText;

  return !checkIfUserLoggedIn() ? (
    <NotLoggedIn />
  ) : (
    <Input
      className={`pt0 ${props?.className}`}
      style={{ ...props?.style }}
      prefix={<FontAwesomeIcon icon={faSearch} />}
      placeholder={placeholderText}
      values={mixText}
      type="text"
      onClick={(e) => props.updateKeyword(e.target.value)}
      name="mixText"
      onChange={(e) => {
        setMixText(e.target.value);
        props.updateKeyword(e.target.value);
      }}
    />
  );
};

export default SearchTextBlock;
