import React from "react";

export function handleRedirect (history, action) {
   const currentKey = window.localStorage.getItem('currentkey');
       switch (currentKey) {
        case "savedRecipes":
           action === "redirect" ? window.location.href="/saved-recipes" :  history.push('/saved-recipes');
        break;
        case "groceryList":
            action === "redirect" ? window.location.href="/grocery-list" : history.push('/grocery-list');
        break;
        default:
            action === "redirect" ? window.location.href="/" :  history.push('/');
        break;
       }
       return true;
}
