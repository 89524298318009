import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification, Spin } from "antd";
import { createKrogerKeysRequest } from "../../redux/actions/create-kroger-keys";

const KrogerSuccess = (props) => {
  const dispatch = useDispatch(),
    [loading, setLoading] = useState(true),
    { createKrogerKeysData } = useSelector((state) => ({ createKrogerKeysData: state.createKrogerKeysData }));
  const query = new URLSearchParams(props.location.search);
  const [krogerCode, setKrogerCode] = useState(query.get("code"));

  useEffect(() => {
    console.log("kroger state changed => ", createKrogerKeysData);
    if (!createKrogerKeysData?.error && Object.keys(createKrogerKeysData?.payload).length) {
      /*  window.localStorage.setItem('kroger', JSON.stringify(krogerData.payload.data.krogerTokens));
        window.localStorage.setItem('krogerCode', krogerData.payload.data.code);
        window.localStorage.setItem('firstKrogerLogin', false);
        window.localStorage.removeItem('cartToShop');
        */

      const timer = setTimeout(() => {
        setLoading(false);
        window.close();
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      console.log("createKrogerKeysData error", createKrogerKeysData?.error?.message);

      if (createKrogerKeysData?.error?.message) {
        setLoading(false);
        notification.error({ message: "Error", description: createKrogerKeysData?.error?.message, placement: "topRight" });
      }
    }
  }, [createKrogerKeysData]);

  useEffect(() => {
    if (krogerCode !== null) {
      setLoading(true);
      dispatch(createKrogerKeysRequest({ code: krogerCode }));
    }
  }, [krogerCode]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <div className="text-center mt-50">
      <h5 className="modal-title" id="exampleModalLabel">
        {krogerCode !== null ? "You are authorized!" : "We are facing some issue. Please try again"}
      </h5>
      {loading && krogerCode !== null && (
        <Spin tip=" Please wait.. Your login process is about to complete...." className="mt-50">
          {" "}
        </Spin>
      )}
    </div>
  );
};

export default KrogerSuccess;
