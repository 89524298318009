import React, { useState, useEffect } from "react";
import { Button, Image, notification, Typography } from "antd";
import { createInstacartShop } from "../../modules/ajax/create-instacart-shop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import Colors from "../../theme/Colors";

const InstaCartShop = (props) => {
  const [isLoading, setLoading] = useState(false);

  const proceedToCart = () => {
    setLoading(true);
    const groceryId = window.localStorage.getItem("groceryId");
    createInstacartShop({ groceryId })
      .then((res) => {
        setLoading(false);
        if (res?.error === false) {
          window.location.href = res?.data?.instaUrl;
          notification.success({ message: "Success", description: res?.message, placement: "topRight" });
        } else {
          notification.error({ message: "Error", description: res?.message, placement: "topRight" });
        }
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: "Error", description: err?.message, placement: "topRight" });
      });
  };

  // useEffect(() => {
  //   console.log("instaCartData state changed => ", instaCartData);
  //   if (!instaCartData.error && Object.keys(instaCartData?.payload).length) {
  //     console.log("instaCartData", instaCartData?.payload.data);
  //     setInstacartUrl(instaCartData?.payload?.data.instaUrl);
  //     setCurrentStep(2);
  //   } else {
  //     if (instaCartData?.error?.message) {
  //       setCartLoading(false);
  //       notification.error({ message: "Error", description: instaCartData?.error?.message, placement: "topRight" });
  //     }
  //   }
  // }, [instaCartData]);

  return (
    <>
      <Typography.Text>
        <FontAwesomeIcon icon={faCircle} style={{ color: false ? Colors.darkText : Colors.primaryColor, fontSize: "12px" }} /> {false ? "Not Connected" : "Connected"}
      </Typography.Text>
      <Image preview={false} src={"/img/store/instacart.png"} alt={"instacart logo"} style={{ objectFit: "contain" }} />
      <Button type="primary" loading={isLoading} disabled={isLoading} onClick={proceedToCart}>
        Proceed to Instacart
      </Button>
    </>
  );
};
export default InstaCartShop;
