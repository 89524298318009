import React from "react";

import { Card, Image } from "antd";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";

const StoreTile = (props) => {
  let selectedStore = props?.selectedStore,
    storeDetailInLoop = props?.storeDetailInLoop;
  return (
    <Card
      className={`store-list-item cursor-pointer`}
      style={{
        border: selectedStore === storeDetailInLoop?.key && props?.showArrow ? `1px solid red` : undefined,
      }}
      key={storeDetailInLoop?.key}
      onClick={(e) => {
         props?.changeSelection(storeDetailInLoop?.key);
      }}
    >
      <div className="store-tile-container">
        <div>{storeDetailInLoop?.connectStatus}</div>
        <div className="text-center">
          <Image className="img-fluid" preview={false} style={{ height: "auto" }} src={storeDetailInLoop?.image} />
        </div>
        <div className="forward">{<FontAwesomeIcon icon={faArrowRight} />}</div>
      </div>
    </Card>
  );
};

export default StoreTile;
