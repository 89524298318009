import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginSetUp from "../GoogleLoginSetUp";
import { CONFIG } from "../../../../config/config";

export default function GoogleLoginForm() {
  return (
    <GoogleOAuthProvider clientId={CONFIG.GOOGLE_CLIENT_ID || ""}>
      <GoogleLoginSetUp />
    </GoogleOAuthProvider>
  );
}
