import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const setPasswordRequest = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: "SET_PASSWORD_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "patch",
                url: CONFIG.BACKEND_API_URL + "user/password/set",
                data,
                auth: 0
            })
            //console.log("RESETPasswordRequest response => ", response);
            if(response && response.statusCode === 200 && response.error === false) {
                // localStorage.removeItem(CONFIG.LOCALSTORAGE_USER);
                dispatch({type: "SET_PASSWORD_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "SET_PASSWORD_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error in RESETPasswordRequest => ", err);
            dispatch({type: "SET_PASSWORD_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}

export const setPasswordReset = () => {
    return async (dispatch) => {
        dispatch({type: "SET_PASSWORD_RESET"});
    }
}