import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { checkIfUserLoggedIn } from "./helper";
import NavMenu from "./components/NavMenu";
import "./App.scss";
import ThemeProvider from "./theme/ThemeProvider";

function App() {
  return (
    <ThemeProvider>
      <Router>
        <NavMenu type={checkIfUserLoggedIn()} />
      </Router>
    </ThemeProvider>
  );
}
export default App;
