import axios from "axios";
import { CONFIG } from "../config/config";

export const apiCall = async ( { method, url, headers, data, auth } ) => {
    try {
        const axiosConfig = {
            method,
            url,
            headers
        };
        
        //console.log("auth", auth);
        if(auth === 1 ) {
            let token = JSON.parse(localStorage.getItem(CONFIG.LOCALSTORAGE_USER))?.token;
            if (token) {
             axiosConfig.headers = { ...axiosConfig.headers, "Authorization": `Bearer ${token}` }
            }
        } else if(auth === 2) {
            // let token = JSON.parse(localStorage.getItem(CONFIG.LOCALSTORAGE_USER)).refresh_token;
            // data.refreshToken = token;
        }
        //console.log('headers => ', headers, data, method);
        if(method === "post" || "patch") axiosConfig.data = data;

        //console.log("axiosConfig", axiosConfig);
        const response =  await axios(axiosConfig);
        //console.log("main api call reponse => ", response)
        return response;
    } catch(err) {
        //console.log("Error in api call => ", err);
        if(err?.message === "Network Error"){
            throw new Error(err);
          } else {
              if (err?.response?.status == 401) {
                window.localStorage.clear();
                window.location.href = "/";
                return false;
      
                //   window.location.href="/";
              }
              throw new Error(err);
            }
        }
    
}