import React, { useContext, useState, useEffect, useRef, forwardRef } from "react";
import { Table, Input, Button, Form } from "antd";
import "./index.scss";
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { updateGroceryData } from "../../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
const EditableContext = React.createContext(null);

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false),
    inputRef = useRef(null),
    form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      //console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} id={dataIndex} name={dataIndex} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const GroceryDataTable = forwardRef((props) => {
  const data = props?.gData?.items ? props?.gData?.items : props?.gData;
  const dataSourceRaw = props?.gData?.items ? props?.gData?.items : props?.gData;
  const [dataSource, setDataSource] = useState(data);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeId, setActiveId] = useState(null),
    [count, setCount] = useState(dataSourceRaw?.length);

  const handleSave = (row) => {
      const newData = [...dataSource];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      // setDataSource(newData);
      let newDataValues = updateGroceryData(props.gData, newData);
      console.log("newDataValues", newDataValues);
      props.updateData(newDataValues.items);
    },
    onSelectChange = (selectedRowKeys) => {
      props.updateData(
        props.gData.items.map((item, index) => {
          return {
            ...item,
            selected: selectedRowKeys?.includes(index),
          };
        })
      );
      // setSelectedRowKeys(selectedRowKeys);
    },
    rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
        {
          key: "odd",
          text: "Select Odd Row",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            setSelectedRowKeys(newSelectedRowKeys);
          },
        },
        {
          key: "even",
          text: "Select Even Row",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            setSelectedRowKeys(newSelectedRowKeys);
          },
        },
      ],
    };

  let columns = [
    // {
    //   key: "dragHandle",
    //   dataIndex: "dragHandle",
    //   width: 30,
    //   render: () => <FontAwesomeIcon icon={faBars} />,
    //   title: "",
    // },
    {
      title: "#",
      width: 60,
      dataIndex: "quantity",
      editable: true,
      key: "quantity",
    },
    // {
    //   title: "Unit",
    //   width: 35,
    //   dataIndex: "unit",
    //   editable: true,
    //   key: "unit",
    // },
    {
      title: "Item",
      width: 130,
      dataIndex: "name",
      editable: true,
      key: "name",
    },
    // {
    //   key: "delete",
    //   dataIndex: "delete",
    //   width: 30,
    //   render: (_, renderData) => (
    //     <>
    //       <FontAwesomeIcon
    //         icon={faTrash}
    //         style={{ cursor: "pointer" }}
    //         onClick={() => {
    //           props.updateData(props.gData.items.filter((item) => item._id !== renderData._id));
    //         }}
    //       />
    //     </>
    //   ),
    //   title: "",
    // },
  ];

  columns = columns.map((col) => {
    if (!col.editable) {
      console.log("col not editable", col);
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  useEffect(() => {
    setCount(count);
  }, [count]);

  useEffect(() => {
    setDataSource(props?.gData?.items);

    const selectedKeys = [];
    for (let i = 0; i < props.gData.items.length; i++) {
      if (props.gData.items[i].selected) {
        selectedKeys.push(i);
      }
    }
    setSelectedRowKeys(selectedKeys);
  }, [props?.gData]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    console.log(active.id);
    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setDataSource((items) => {
        const oldIndex = items.findIndex((item) => item.key === active.id);
        const newIndex = items.findIndex((item) => item.key === over.id);
        let newDataValues = updateGroceryData(props.gData, items);
        props.updateData(newDataValues);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    // Stop overlay.
    setActiveId(null);
  }
  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Table
          columns={columns}
          className={`${props?.className}`}
          rowClassName={() => "editable-row"}
          rowSelection={rowSelection}
          dataSource={dataSource}
          components={{
            body: {
              wrapper: DraggableWrapper,
              row: DraggableRow,
              cell: EditableCell,
            },
          }}
          pagination={false}
          scroll={{ y: 400 }}
        />
        {/* Render overlay component. */}
        <DragOverlay>
          <Table columns={columns} scroll={{ y: 350 }} dataSource={activeId ? new Array(1).fill(dataSource[dataSource.findIndex((item) => item.key === activeId)]) : []} pagination={false} />
        </DragOverlay>
      </DndContext>
    </>
  );

  function DraggableWrapper(props) {
    const { children, ...restProps } = props;
    /**
     * 'children[1]` is `dataSource`
     * Check if `children[1]` is an array
     * because antd gives 'No Data' element when `dataSource` is an empty array
     */
    return (
      <SortableContext items={children[1] instanceof Array ? children[1].map((child) => child.key) : []} strategy={verticalListSortingStrategy} {...restProps}>
        <tbody {...restProps}>
          {
            // This invokes `Table.components.body.row` for each element of `children`.
            children
          }
        </tbody>
      </SortableContext>
    );
  }

  function DraggableRow(props) {
    const { attributes, listeners, setNodeRef, isDragging, overIndex, index } = useSortable({
      id: props["data-row-key"],
    });
    const isOver = overIndex === index;
    const { children, ...restProps } = props;
    const isData = children instanceof Array;
    const style = {
      ...restProps?.style,
      ...(isData && isDragging ? { background: "#80808038" } : {}),
      ...(isData && isOver ? { borderTop: "5px solid #ec161638" } : {}),
    };
    /**
     * 'children[1]` is a row of `dataSource`
     * Check if `children[1]` is an array
     * because antd gives 'No Data' element when `dataSource` is an empty array
     */
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr ref={setNodeRef} {...attributes} {...restProps} style={style}>
            {children instanceof Array
              ? children.map((child) => {
                  const { children, key, ...restProps } = child;
                  return key === "dragHandle" ? (
                    <td {...listeners} {...restProps}>
                      {child}
                    </td>
                  ) : (
                    <td {...restProps}>{child}</td>
                  );
                })
              : children}
          </tr>
        </EditableContext.Provider>
      </Form>
    );
  }
});
export default GroceryDataTable;
