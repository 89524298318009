import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, notification, Spin, Image, Typography } from "antd";
import { getKrogerKeys } from "../../modules/ajax/get-kroger-keys";
import { createKrogerLogin } from "../../modules/ajax/create-kroger-login";
import { createKrogerCart } from "../../modules/ajax/create-kroger-cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import Colors from "../../theme/Colors";

const KrogerShop = (props) => {
  const [isLoading, setLoading] = useState(false),
    [alreadyLogin, setAlreadyLogin] = useState(props?.connectStatus === "Connected" ? true : false),
    proceedToCart = async () => {
      if (alreadyLogin) {
        setLoading(true);
        const cartStatus = await createKrogerCart();
        if (cartStatus?.data?.cartStatus === true) {
          console.log("cartStatus", cartStatus);
          window.localStorage.removeItem("groceryId");
          window.location.href = "https://www.kroger.com/cart";
        } else {
          if (cartStatus?.data?.needLogin === true) {
            notification.error({ message: "Error", description: "Your session expired and need to login again.", placement: "topRight" });
            setAlreadyLogin(false);
          }
        }
        setLoading(false);
      } else {
        createKrogerLogin()
          .then((res) => {
            console.log("res", res);
            const interval = setInterval(async () => {
              const findLoggedIn = await checkIfKrogerLoggedIn(interval);
              if (findLoggedIn?.loginStatus === true) {
                if (interval) clearInterval(interval);
              }
            }, 2000);
            return () => clearInterval(interval);
          })
          .catch((err) => {
            notification.error({ message: "Success", description: err?.message, placement: "topRight" });
          });
      }
    },
    checkIfKrogerLoggedIn = async () => {
      const isLoggedInResponse = await getKrogerKeys();
      if (isLoggedInResponse && isLoggedInResponse?.data) {
        if (isLoggedInResponse?.data?.loginStatus === true) {
          setAlreadyLogin(true);
          props?.updateConnectStatus("Connected");
        }
        setLoading(false);
        return isLoggedInResponse?.data;
      } else {
        return isLoggedInResponse;
      }
    };

  return (
    <>
      <Typography.Text>
        <FontAwesomeIcon icon={faCircle} style={{ color: !alreadyLogin ? Colors.darkText : Colors.primaryColor, fontSize: "12px" }} /> {!alreadyLogin ? "Not Connected" : "Connected"}
      </Typography.Text>
      <Image preview={false} src={"/img/store/kroger.png"} alt={"Kroger logo"} style={{ objectFit: "contain" }} />
      <Button type="primary" loading={isLoading} disabled={isLoading} onClick={proceedToCart}>
        {alreadyLogin ? "Proceed to Kroger" : "Connect"}
      </Button>
    </>
  );
};
export default KrogerShop;
