import { CONFIG } from "./config/config";

export const getAccessToken = () => (localStorage.getItem("USER") ? (JSON.parse(localStorage.getItem("USER")).userRole === "manager" || JSON.parse(localStorage.getItem("USER")).userRole === "owner" ? "owner" : JSON.parse(localStorage.getItem("USER")).userRole) : "");
export const setAccessToken = (a) => {
  localStorage.setItem("accessToken", a);
};

export const getKrogerKeysfromLS = () => {
  let krogerKeysFromLS = window.localStorage.getItem("kroger");
  if (krogerKeysFromLS) {
    let krogerKeys = JSON.parse(krogerKeysFromLS);
    if (krogerKeys && krogerKeys?.loginStatus === true && krogerKeys?.access_token) {
      return krogerKeys;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const updateGroceryData = (keyData, finalResults) => {
  let setGroceryItemsToNewList = {};
  setGroceryItemsToNewList.key = keyData.key;
  setGroceryItemsToNewList.title = keyData.title; //New Grocery Flow
  setGroceryItemsToNewList.items = finalResults;
  return setGroceryItemsToNewList;
};

export const updateGroceryListing = (keyData, finalResults) => {
  let setGroceryItemsToNewList = {};
  setGroceryItemsToNewList.key = keyData.key;
  setGroceryItemsToNewList.title = keyData.title; //New Grocery Flow
  setGroceryItemsToNewList.items = finalResults;
  window.localStorage.setItem(setGroceryItemsToNewList.key, JSON.stringify(setGroceryItemsToNewList));
};

export const getGroceryListByKey = (lsKey) => {
  let gList = window.localStorage.getItem(lsKey);
  if (gList) {
    return JSON.parse(gList);
  } else {
    return null;
  }
};

export const formatGroceryList = (data, itemLength) => {
  if (itemLength === null) itemLength = 0;

  let newData = [];
  for (let i = 0; i < data.length; i++) {
    newData[i] = {};
    newData[i] = data[i];
    newData[i].key = itemLength++;
    newData[i].index = newData[i].key;
  }
  return newData;
};

export const formatGroceryListbk = (data, oldData) => {
  let itemLength = oldData ? oldData.items.length : 0;

  let newData = [];
  for (let i = 0; i < data.length; i++) {
    newData[i] = {};
    newData[i].key = itemLength++;
    newData[i].item = data[i];
    newData[i].index = newData[i].key;
  }
  return newData;
};

export const getGroceryListKeysFromLS = () => {
  let lsKeys = [];
  for (let [key, value] of Object.entries(localStorage)) {
    if (key.includes("gl")) {
      lsKeys.push(JSON.parse(value));
      //  let lsValues = localStorage.getItem(key),
      //	finalVal = JSON.parse(lsValues);
      //if (finalVal) {
      //finalVal.forEach(v => {
      //setRowState((rowState) => [...rowState, { ...findMap(v) }]);
      //});
    }
  }
  return lsKeys;
  //return (lsKeys) ? JSON.parse(lsKeys): null;
};

export function checkIfUserLoggedIn() {
  let loggedInUser = JSON.parse(window.localStorage.getItem(CONFIG.LOCALSTORAGE_USER));
  return loggedInUser ? true : false;
}

export function getLocalStorageDataForUser() {
  let loggedInUser = JSON.parse(window.localStorage.getItem(CONFIG.LOCALSTORAGE_USER));
  return loggedInUser ? loggedInUser : {};
}
export function getLoggedInUserName() {
  let loggedInUser = JSON.parse(window.localStorage.getItem(CONFIG.LOCALSTORAGE_USER));
  return loggedInUser ? loggedInUser.firstName : "";
}

export function getLoggedInSource() {
  let loggedInUser = JSON.parse(window.localStorage.getItem(CONFIG.LOCALSTORAGE_USER));
  return loggedInUser ? loggedInUser.source : "";
}

function json2array(json) {
  var result = [];
  var keys = Object.keys(json);
  keys.forEach(function (key) {
    result.push(json[key]);
  });
  return result;
}

export function getGroceryListData(groceryListName) {
  if (groceryListName) {
    let listData = localStorage.getItem(groceryListName);
    if (listData) {
      return JSON.parse(listData);
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function updateLocalStorage(data) {
  let token = JSON.parse(localStorage.getItem(CONFIG.LOCALSTORAGE_USER))?.token,
    obj = {
      firstName: data.firstName,
      lastName: data.lastName,
      zPinCode: data.zPinCode,
      email: data.email,
      source: data.source,
      profileImage: data?.profileImage,
    };
  obj.token = token ? token : data.token;

  localStorage.setItem(CONFIG.LOCALSTORAGE_USER, JSON.stringify(obj));

  return true;
}

export function clearCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var spcook = cookies[i].split("=");
    document.cookie = spcook[0] + "=;expires=Thu, 21 Sep 1979 00:00:01 UTC;";
  }
}
export function callLogout() {
  const localData = getLocalStorageDataForUser();
  if (localData && localData.source === "facebook") {
    window.FB.getLoginStatus(function (response) {
      console.log("Get logn status in logout", response);
      window.FB.logout(function (response) {
        console.log("within logout call", response);
      });
    });
  }
  localStorage.removeItem(CONFIG.LOCALSTORAGE_USER);
  localStorage.clear();
  sessionStorage.clear();
  clearCookies();
  //window.location.href = "/";

  localStorage.setItem("gactiveMenu", "home");
}

export const required = (value) => {
  return value ? undefined : "Required";
};

export const isEqualToPasswordField = (value, values = {}) => (value === values.password ? undefined : "Confirm Password do not match password.");

export const isEmail = (value) => {
  const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  return emailregex.test(value) ? undefined : "Enter valid email";
};

export const mustBeNumber = (value) => (isNaN(value) ? "Must be a number" : undefined);

export const mustBeAlphaNummer = (value) => {
  return /^[a-z0-9A-Z]+$/gi.test(value) ? undefined : "Must be a alphanumeric";
};

export const minValue = (min) => (value) => isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const composeValidators =
  (...validators) =>
  (value, values) =>
    validators.reduce((error, validator) => error || validator(value, values), undefined);

export const max3Numbers = (value) => {
  //console.log('max3Numbers', value, typeof (value), value.length);
  const temp = value + "";
  return temp.length <= 2 ? undefined : "Maximum 2 numbers";
};

export const resolveWith = (a) => Promise.resolve(a);

export const activeLangOptions = (arr = []) => {
  return arr.filter((lang) => Boolean(lang.is_active)).map((data) => ({ label: data.language_name, value: data.language_id }));
};

export const compareTime = (to, from, msg) => {
  //console.log(to);
  //console.log(from);
  if (to !== "" && to !== undefined && to !== null && from !== "" && from !== null && from !== undefined) {
    let first_date = new Date();
    let second_date = new Date();
    if (from) {
      let time = from.split(":");
      if (time.length) {
        first_date.setHours(time[0]);
        first_date.setMinutes(time[1]);
        first_date.setSeconds(time[2]);
      }
    }
    if (to) {
      let time = to.split(":");
      if (time.length) {
        second_date.setHours(time[0]);
        second_date.setMinutes(time[1]);
        second_date.setSeconds(time[2]);
      }
    }
    if (first_date > second_date) {
      return msg;
    }
  }
};

export const validateImageFile = (fileExt) => {
  let validExt = ["image/jpg", "image/JPG", "image/JPEG", "image/jpeg", "image/gif", "image/GIF", "image/png", "image/PNG"];
  if (validExt.includes(fileExt)) {
    return true;
  } else {
    return false;
  }
};

export const getAge = (dateString) => {
  var today = new Date(),
    birthDate = new Date(dateString),
    age = today.getFullYear() - birthDate.getFullYear(),
    m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const isDate = (s) => {
  if (isNaN(s) && !isNaN(Date.parse(s))) return true;
  else return false;
};

export const findDateYear = (age) => {
  let TODAY = new Date(),
    finalDob = new Date(TODAY).getFullYear() - age + "-" + new Date(TODAY).getMonth() + "-" + new Date(TODAY).getDate();
  return finalDob;
};
export const formatDateTime = (dateString) => {
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    birthDate = new Date(dateString);
  if (isDate(dateString)) {
    //	//console.log("d");
    //	return (birthDate.getMonth()+1) + '/' + birthDate.getDate() + '/' +  birthDate.getFullYear() + " " + birthDate.getHours() + ":" + birthDate.getMinutes() + ":" + birthDate.getSeconds();
    return birthDate.getDate() + " " + months[birthDate.getMonth()] + " " + birthDate.getFullYear();
  } else {
    return "";
  }
};

export const formatTodayDate = () => {
  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  var d = new Date();
  var minutes = d.getMinutes();
  minutes = minutes > 9 ? minutes : "0" + minutes;
  return d.getDate() + " " + months[d.getMonth()] + " " + d.getFullYear() + " " + (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) + ":" + minutes + " " + (d.getHours() >= 12 ? "PM" : "AM");
};

export const createUniqueID = (length = 16) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const isFormInValid = (form) => {
  return form.getFieldsError().some((item) => item.errors.length > 0);
};
