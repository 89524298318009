import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { socialLoginRequest } from "../../../../redux/actions/social-login";
import { notification } from "antd";
import { updateLocalStorage, clearCookies } from "../../../../helper";
import SocialButton from "../../../CommonWidgets/SocialButton";
import { CONFIG } from "../../../../config/config";
import { handleRedirect } from "../../../../modules/handle-redirect";
import { useHistory } from "react-router";

export default function FacebookLoginForm(props) {
  const dispatch = useDispatch(),
  history = useHistory(),
    { socialLoginData } = useSelector((state) => ({ socialLoginData: state.socialLoginData })),
    formatRequestObject = (response) => {
      console.log("format request obj", response);
      let finalObj = {};
      finalObj.socialId = response?.userID;
      finalObj.source = "facebook";
      finalObj.platformToken = response?.accessToken;
      finalObj.profileImage = response?.picture?.data?.url;
      finalObj.firstName = response?.first_name;
      finalObj.lastName = response?.last_name;
      finalObj.email = response?.email;
      return finalObj;
    },
    handleFacebookLogin = () => {
      window.FB.getLoginStatus(function (response) {
        console.log("fb login rsp", response);
        if (response.status === "connected") {
          if (response?.authResponse !== null) {
            const requestObj = formatRequestObject(response?.authResponse);
            dispatch(socialLoginRequest(requestObj));
          }
        } else {
          window.FB.login(
            function (loginResp) {
              if (loginResp?.authResponse !== null) {
                const requestObj = formatRequestObject(loginResp?.authResponse);
                dispatch(socialLoginRequest(requestObj));
              }
            },
            { scope: "public_profile,email" }
          );
        }
      });
    };

  useEffect(() => {
    if (!socialLoginData.error && Object.keys(socialLoginData?.payload).length) {
      let profileInfo = socialLoginData?.payload?.data.userProfile;
      profileInfo.token = socialLoginData?.payload?.data.token;
      window.localStorage.removeItem(CONFIG.LOCALSTORAGE_USER);
      updateLocalStorage(profileInfo);
       if (props?.pin) {
          window.localStorage.removeItem('pin');
       }
       handleRedirect(history, "redirect");
     // window.location.href = "/saved-recipes";
    } else {
      if (socialLoginData?.error?.message) {
        console.log("abcd", socialLoginData?.error);
        notification.error({ message: "Error", description: socialLoginData?.error?.message, placement: "topRight" });
      }
    }
  }, [socialLoginData]);

  useEffect(() => {
    clearCookies();
  }, []);

  return (
    
  <SocialButton socialIconStyle="fb-text" socialTextStyle="fb-text"  socialText="Facebook" socialIcon={faFacebook} socialButtonStyle="fb-button" onClick={() => handleFacebookLogin()} />
  );
}

{
  /* <LoginSocialFacebook
      isOnlyGetToken={false}
      appId={CONFIG.FB_APP_ID || ""}
      fieldsProfile={"id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"}
      onLoginStart={onLoginStart}
      onResolve={({ provider, data }) => {
        setProvider(provider);
        setProfile(data);
        let finalObj = {};
        finalObj.socialId = data?.id;
        finalObj.source = "facebook";
        finalObj.platformToken = data.accessToken;
        finalObj.profileImage = data?.picture?.data?.url;
        finalObj.firstName = data?.first_name;
        finalObj.lastName = data?.last_name;
        finalObj.email = data?.email;
        dispatch(socialLoginRequest(finalObj));
      }}
      onLogoutSuccess={onLogoutSuccess}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <FacebookLoginButton className="social-button" />
    </LoginSocialFacebook> 
  ); */
}
