import { CONFIG } from "../../config/config";

const logout = (
  state = {
    loading: false,
    payload: {},
    error: null,
  },
  action
) => {
  switch (action.type) {
    case "LOGOUT_REQUEST":
      return { ...state, loading: true, payload: {}, error: null };
    case "LOGOUT_REQUEST_SUCCESS":
      localStorage.removeItem(CONFIG.LOCALSTORAGE_USER);
      return { ...state, loading: false, payload: action.payload, error: null };
    case "LOGOUT_REQUEST_ERROR":
      return { ...state, loading: false, payload: {}, error: action.payload };
    case "LOGOUT_REQUEST_RESET":
      return { loading: false, payload: {}, error: null };
    default:
      return state;
  }
};
export default logout;
