const uploadToAws =  (  state = {
    loading: false,
    payload: {},
    error: null,
  },
  action
) => {
  switch (action.type) {
    case "UPLOAD_AWS_REQUEST":
      return { ...state, loading: true, payload: {}, error: null };
    case "UPLOAD_AWS_REQUEST_SUCCESS":
      /* let obj = { access_token: action?.payload?.data.access_token , email};
            localStorage.setItem(CONFIG.LOCALSTORAGE_USER, JSON.stringify(obj)); */
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          ...action.payload,
        },
        error: null,
      };
    case "UPLOAD_AWS_REQUEST_ERROR":
      return { ...state, loading: false, payload: {}, error: action.payload };
    case "UPLOAD_AWS_REQUEST_RESET":
      return { loading: false, payload: {}, error: null };
    default:
      return state;
  }
};


export default uploadToAws