import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification, Button, Popconfirm, Card, Typography } from "antd";
import ChooseStoreModal from "../ChooseStoreModal";
import SelectExistingGroceryListName from "../SelectExistingGroceryListName";
import ShowSelectedItems from "../ShowSelectedItems";
import GroceryShoppingModal from "../GroceryShoppingModal";
import { groceryListRequest } from "../../redux/actions/grocery";
import { createGroceryListRequest, createGroceryListReset } from "../../redux/actions/create-grocery";
import { createGroceryList } from "../../modules/ajax/create-grocery";
import ModalContainer from "../CommonWidgets/ModalContainer";
import ModalHeader from "../CommonWidgets/ModalHeader";
import { faShop } from "@fortawesome/pro-regular-svg-icons";
import { formatTodayDate, formatGroceryList } from "../../helper";
import "./index.scss";
import NewGroceryListName from "../NewGroceryListName";
const { Title } = Typography;

const GroceryListingSetUpModal = memo((props) => {
  const [currentPage, setCurrentPage] = useState(props?.edit === 1 ? 2 : 1),
    [clickedOption, setClickedOption] = useState(""),
    [masterGroceryList, setMasterGroceryList] = useState([]),
    [selectedGroceryListToShop, setSelectedGroceryListToShop] = useState(props?.selectedItems ? props?.selectedItems : {}),
    { createGroceryData, groceryData } = useSelector((state) => ({ createGroceryData: state.createGroceryData, groceryData: state.groceryData })),
    dispatch = useDispatch(),
    [currentHeader, setCurrentHeader] = useState("Select Grocery List"),
    changePage = (pageNo) => {
      setCurrentPage(pageNo);
      switch (pageNo) {
        case 1:
          setCurrentHeader("Select Grocery List");
          break;
        case 2:
          setCurrentHeader("Your Cart");
          break;
        case 3:
          setCurrentHeader("Choose your store");
          break;
        default:
          setCurrentHeader("");
          break;
      }
    },
    chosenList = (clickedBtn) => {
      setClickedOption(clickedBtn);
      saveGroceryListToDB(clickedBtn);
    }, //FINAL DB CALL TO CREATE GROCERY LIST
    saveGroceryListToDB = (clickedBtn) => {
      let finalData = selectedGroceryListToShop;
      console.log("final to db", finalData);
      if (finalData || finalData?.items) {
        if (props?.recipeDetail) {
          finalData.recipeDetail = props?.recipeDetail;
        }
        if (props?.edit === 1) {
          createGroceryList(finalData)
            .then((res) => {
              if (res?.error === false) {
                window.localStorage.setItem("groceryId", res?.data._id);
                notification.success({ message: "Success", description: res?.message, placement: "topRight" });
                if (clickedBtn === "save-shop") {
                  changePage(3);
                }
                dispatch(groceryListRequest());
              } else {
                notification.error({ message: "Success", description: res?.message, placement: "topRight" });
              }
            })
            .catch((err) => {
              notification.error({ message: "Success", description: err?.message, placement: "topRight" });
            });
        } else {
          dispatch(createGroceryListRequest(finalData));
        }
      }
    },
    //SETING SELECTED GROCERY LIST NAME + DATA TO START SHOPPING
    manageGrocery = (groceryType) => {
      let localStorageGroceryData = props?.selectedItems,
        abc = masterGroceryList.filter((it) => it._id === selectedGroceryListToShop.key);
      console.log("groceryType", groceryType, abc);
      if (localStorageGroceryData) {
        if (groceryType === "newGrocery") {
          let uniqueData = localStorageGroceryData?.items.filter((ele, ind) => ind === localStorageGroceryData?.items?.findIndex((elem) => elem._id === ele._id));
          let trimData;

          if (uniqueData?.length) {
            trimData = formatGroceryList(uniqueData, 0);
          }
          let newList = {};
          newList.key = Math.random();
          newList.items = trimData;
          newList.title = formatTodayDate();
          localStorageGroceryData = newList;
        } else {
          let doesGrocerylistExist = abc[0];
          if (doesGrocerylistExist) {
            localStorageGroceryData.key = selectedGroceryListToShop.key;
            localStorageGroceryData.title = selectedGroceryListToShop.title;
            let finalData = [...doesGrocerylistExist.items, ...localStorageGroceryData.items];
            let trimmedData = finalData.filter((ele, ind) => ind === finalData.findIndex((elem) => elem._id === ele._id));
            if (trimmedData?.length) {
              finalData = formatGroceryList(trimmedData, 0);
            }
            localStorageGroceryData.items = finalData;
          }
        }

        changePage(2);
        console.log("source", groceryType, localStorageGroceryData);
        setSelectedGroceryListToShop(localStorageGroceryData);
      }
    };

  useEffect(() => {
    if (!createGroceryData.error && Object.keys(createGroceryData?.payload).length) {
      window.localStorage.setItem("groceryId", createGroceryData?.payload?.data._id);
      notification.success({ message: "Success", description: createGroceryData?.payload?.message, placement: "topRight" });
      if (clickedOption === "save-shop") changePage(3);
      props?.resetCartItems && typeof props.resetCartItems === "function" && props.resetCartItems();
      dispatch(createGroceryListReset());
    } else {
      if (createGroceryData?.error?.message) notification.error({ message: "Error", description: createGroceryData?.error?.message, placement: "topRight" });
    }
  }, [createGroceryData]);

  useEffect(() => {
    if (!groceryData.error && Object.keys(groceryData?.payload).length) {
      setMasterGroceryList(groceryData?.payload?.data);
    } else {
      // if (groceryData?.error?.message) notification.error({ message: "Error", description: groceryData?.error?.message, placement: "topRight" });
      setMasterGroceryList([]);
    }
  }, [groceryData]);

  useEffect(() => {
    if (currentPage === 1) dispatch(groceryListRequest());
  }, []);

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage]);

  return (
    props?.open && (
      <ModalContainer
        isOpen={props?.open}
        style={{ overflowY: "auto", padding: 0 }}
        width={900}
        title={
          <ModalHeader
            titleIcon={faShop}
            modalTitle={currentHeader}
            close={() => {
              props.close();
              props?.resetCartItems && typeof props.resetCartItems === "function" && props.resetCartItems();
              setCurrentPage(props.myPage ? props.myPage : 1);
              /* if (props?.edit === 1) {
                const timer = setTimeout(() => {
                  window.location.reload();
                }, 500);
                return () => clearTimeout(timer); 
              } */
            }}
          />
        }
        footer={[
          currentPage === 2 && (
            <div className="btnShopContainer" key="btnShopContainer">
              <div key="pop">
                {!props.edit && (
                  <Popconfirm
                    placement="topRight"
                    key="popConfirm"
                    title={"Your changes will be unsaved. Are you sure to cancel?"}
                    onConfirm={() => {
                      if (props.edit && props.edit === true) {
                        props.close();
                      }
                      changePage(1);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary" key="cancel">
                      Cancel
                    </Button>
                  </Popconfirm>
                )}
              </div>

              {"    "}
              <div>
                <Button
                  type={"primary"}
                  className={"ml2"}
                  key={"btnSaveAndShop"}
                  onClick={(e) => {
                    chosenList("save-shop");
                  }}
                >
                  Save and Shop
                </Button>

                <Button
                  type={"primary"}
                  key="btnSave"
                  className="ml2"
                  style={{ float: "right" }}
                  onClick={(e) => {
                    chosenList("save");
                    if (props?.edit !== 1) {
                      changePage(2);
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          ),
        ]}
      >
        {currentPage === 1 && (
          <div className="grocery-setup-section">
            <div className="select-block">
              <Title level={5} className="text-center">
                Adding ({props?.selectedItems?.items?.length}) items to Grocery List
              </Title>
              <ShowSelectedItems listItems={props?.selectedItems} />
            </div>
            <Card className="setup-list w-full">
              <div>
                <Title level={5}>Create a New Grocery List</Title>
                <NewGroceryListName
                  data={selectedGroceryListToShop}
                  setPage={changePage}
                  selectedData={chosenList}
                  manageGrocery={manageGrocery}
                  updateNewData={(itemData, title) => {
                    console.log("item data", itemData);
                    let knownData = { ...selectedGroceryListToShop };
                    knownData.items = itemData?.items;
                    if (title) {
                      knownData.title = title;
                    }
                    setSelectedGroceryListToShop(knownData);
                  }}
                />
              </div>
              <div>
                <Title level={5}>Add to an Existing List</Title>
                <SelectExistingGroceryListName
                  page={currentPage}
                  setPage={changePage}
                  inputData={masterGroceryList}
                  selectedRadioGrocerylist={(item) => {
                    setSelectedGroceryListToShop(item);
                  }}
                  manageGrocery={manageGrocery}
                />
              </div>
            </Card>
          </div>
        )}
        {currentPage === 2 && (
          <GroceryShoppingModal
            page={currentPage}
            setPage={changePage}
            data={selectedGroceryListToShop}
            selectedData={chosenList}
            updateNewData={(itemData) => {
              console.log("item data", itemData);
              let knownData = { ...selectedGroceryListToShop };
              knownData.items = itemData?.items;
              if (itemData?.title) {
                knownData.title = itemData?.title;
              }
              setSelectedGroceryListToShop(knownData);
            }}
          />
        )}
        {currentPage === 3 && <ChooseStoreModal page={currentPage} setPage={changePage} data={selectedGroceryListToShop} selectedData={chosenList} edit={props.edit} closeModal={() => props.close()} updatePageHeader={(headerValue) => setCurrentHeader(headerValue)} />}
      </ModalContainer>
    )
  );
});

export default GroceryListingSetUpModal;
