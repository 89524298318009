import React, { useEffect, useState } from "react";
import { List, Typography } from "antd";
import "./index.scss";

const ShowSelectedItems = (props) => {
  const listItems = props?.listItems?.items;
  return listItems && <List size="small" className="show-list-items" header={null} footer={null} bordered dataSource={listItems} renderItem={(item) => <List.Item>{item?.name}</List.Item>} />;
};

export default ShowSelectedItems;
