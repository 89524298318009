import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const createKrogerCart = async () => {
        try {
            const groceryId = window.localStorage.getItem('groceryId');
                if (groceryId) {
                const { data: response } = await apiCall({
                    method: "post",
                    url: CONFIG.BACKEND_API_URL + "user/kroger/cart",
                    data: {"groceryId": groceryId},
                    auth: 1
                });
                //console.log("grocery list response => ", response);

                if (response && response.statusCode === 201 && response.error === false) {
               return response;
            } else {
               return response;
            }
                }
        } catch(err) {
            //console.log("error in groceryListRequest => ", err);
            return err;
        }
    
    
}
