const Colors = {
  primaryColor: "#89CF9D",
  secondaryColor: "#F5F5F5",
  lightAccentColor: "#292929",
   darkText: "#151515",
   mutedText: "#808080",
   whiteText: "#FFFFFF"
  
   
};

export default Colors;
