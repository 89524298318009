import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import FloatInput from "../CommonWidgets/FloatInput";
import { Form, Typography, Button } from "antd";
import { faPenCircle } from "@fortawesome/pro-regular-svg-icons";
import "./index.scss";
const { Link } = Typography;

const NewGroceryListName = (props) => {
  console.log("props in shopping", props);
  const [groceryListName, setGroceryListName] = useState(props?.data?.title ? props?.data?.title : ""), //formatTodayDate()
    [enableEdit, setEnableEdit] = useState(false),
    [dataList, setDataList] = useState(props.data),
    onSaveHeader = () => {
      let updatedList = dataList;
      setDataList(updatedList);
      props.updateNewData(updatedList, groceryListName);
      setEnableEdit(false);
    };

  useEffect(() => {
    setGroceryListName(props.data?.title);
    setDataList(props.data);
    props.updateNewData(props.data, props.data?.title);
  }, []);

  useEffect(() => {
    groceryListName?.length > 0 ? setEnableEdit(true) : setEnableEdit(false);
  }, [groceryListName]);

  return (
    <>
      <div className="shop-text-container">
        <div className="w-full">
          <Form>
            <Form.Item
              name={"groceryHeader"}
              rules={[
                {
                  required: true,
                  message: "Please input the list name",
                },
              ]}
              hasFeedback
            >
              <FloatInput required={true} size="large" label="List Name" placeholder="Enter Grocery List Name" type="text" name="groceryHeader" values={groceryListName} onChange={(e) => setGroceryListName(e.target.value)} />
            </Form.Item>
          </Form>
        </div>
        <div>
          <Button
            key="addNew"
            type="primary"
            className="mw200 h50"
            disabled={!enableEdit}
            onClick={(e) => {
              props?.manageGrocery("newGrocery");
              onSaveHeader();
              props?.setPage(2);
            }}
          >
            Create List
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewGroceryListName;
