export const CONFIG = {
  LOCALSTORAGE_USER: "gatherdUser",
  BACKEND_API_URL:
    //"https://13.57.203.202:8443/v1/",
    //"http://localhost:8443/v1/",
    "https://app.joingatherd.com:8443/v1/",
  GOOGLE_CLIENT_ID: "983390138788-qjuh3tgc9p7qn30muqo29tpm61n44mvq.apps.googleusercontent.com", //"427124889787-gesivai1q08h1ci8fvlgrqcr6eajs9v3.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET: "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", //"49Ypvrk3CfF5USquy74ZHfPj",
  FB_APP_ID: "214814514400635", //"398462181632936",
  ENCODED_KROGER: "Z2F0aGVyZC1jNDI4YjdhZTVmZDdhYzM2MmVhMzQ1ZjEwYTYxYTY0Nzg4NTgxMDE2MjAzNzQ2MzE0MjY6VnNvVHRqVmtPdEZySkFwTjRHSjBPY3Zobk5NT2pnbXlVcUNMQVY3eQ==",
  AWS_ACCESS_KEY: "AKIAZ4GQ4C3GLOZFRKOS",
  AWS_SECRET_KEY: "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",
  GOOGLE_CHROME_EXTENSION_ID: "fckfpbaanhcnagcdhfeeoinjnljdkckc",
};

export const KROGER_CONFIG = {
  KROGER_CLIENT_ID: "gatherd-c428b7ae5fd7ac362ea345f10a61a6478858101620374631426",
  KROGER_CLIENT_SECRET: "VsoTtjVkOtFrJApN4GJ0OcvhnNMOjgmyUqCLAV7y",
  KROGER_BASE_URL: "https://api.kroger.com/v1/",
  KROGER_REDIRECT_URL: "https://app.joingatherd.com/krogercallback",
};
export const KROGER_APIS = {
  KROGER_AUTHORIZE: `${KROGER_CONFIG.KROGER_BASE_URL}connect/oauth2/authorize?response_type=code&client_id=${KROGER_CONFIG.KROGER_CLIENT_ID}&redirect_uri=${KROGER_CONFIG.KROGER_REDIRECT_URL}`,
  KROGER_TOKEN: `${KROGER_CONFIG.KROGER_BASE_URL}connect/oauth2/token`,
  KROGER_PRODUCT_SEARCH: `${KROGER_CONFIG.KROGER_BASE_URL}products`,
  KROGER_CART: `${KROGER_CONFIG.KROGER_BASE_URL}cart/add`,
};
