import React from "react";
import { Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import "./index.scss";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import Colors from "../../../theme/Colors";

const { Title } = Typography;

const ModalHeader = (props) => {
  return (
    <div className="flex w-full align-items-center justify-content-between m0 p0 pt4 pb4" style={props?.style}>
      <Title level={5} className="m0">
        <FontAwesomeIcon icon={props?.titleIcon} /> {props?.modalTitle}
      </Title>
      <FontAwesomeIcon
        icon={faTimes}
        className="m0 cursor-pointer"
        style={{ fontSize: "24px", color: Colors.darkText }}
        onClick={() => {
          console.log("Close");
          props.close();
        }}
      />
    </div>
  );
};
export default ModalHeader;
