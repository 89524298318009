import React from "react";
import "./index.scss";
import { Typography } from "antd";
import UploadFile from "../CommonWidgets/UploadProfilePhoto";
import "./index.scss";
import Colors from "../../theme/Colors";
const { Text, Title } = Typography;

const ViewUserProfileData = (props) => {
  const profileInfo = props?.data;
  return (
    <>
      <div className="text-center mb8">
        <UploadFile title={"Upload Photo"} imageType={"profile-image"} setSignedUrl={() => {}} onResetSignedUrl={() => {}} />
      </div>
      <div className="text-center">
        <Typography.Title className="m0" level={5} style={{ color: Colors.mutedText, fontWeight: "bold" }}>
          Name
        </Typography.Title>
        <Typography.Title className="m0" level={4} style={{ color: Colors.darkText, fontWeight: 400 }}>
          {profileInfo?.firstName} {profileInfo?.lastName}
        </Typography.Title>
      </div>
      <div className="mt3 text-center">
        <Typography.Title className="m0" level={5} style={{ color: Colors.mutedText, fontWeight: "bold" }}>
          Email
        </Typography.Title>
        <Typography.Title className="m0" level={4} style={{ color: Colors.darkText, fontWeight: 400 }}>
          {profileInfo?.email}
        </Typography.Title>
      </div>
      <div className="mt3 text-center">
        <Typography.Title className="m0" level={5} style={{ color: Colors.mutedText, fontWeight: "bold" }}>
          Zip code
        </Typography.Title>
        <Typography.Title className="m0" level={4} style={{ color: Colors.darkText, fontWeight: 400 }}>
          {profileInfo?.zPinCode}
        </Typography.Title>
      </div>
    </>
  );
};
export default ViewUserProfileData;
