import React, { useState, useEffect } from "react";
import { notification, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { viewProfileRequest, editProfileReset } from "../../redux/actions/profile";
import { deleteAccountRequest } from "../../redux/actions/delete-account";
import { updateLocalStorage, callLogout, checkIfUserLoggedIn, getLocalStorageDataForUser } from "../../helper";
import ModalContainer from "../CommonWidgets/ModalContainer";
import ModalHeader from "../CommonWidgets/ModalHeader";
import { faUser, faPencil, faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewUserProfileData from "../ViewUserProfileData";
import EditProfileForm from "../EditProfileForm";

const ProfileModal = (props) => {
  const dispatch = useDispatch(),
    { profileData, deleteAccountData } = useSelector((state) => ({ profileData: state.profileData, deleteAccountData: state.deleteAccountData })),
    [editMode, setEditMode] = useState(1);

  useEffect(() => {
    if (props.viewProfile === 1) {
      // setEditMode(0);
      dispatch(viewProfileRequest());
    }
    return () => {
      dispatch(editProfileReset());
    };
  }, []);

  useEffect(() => {
    try {
      //console.log("Profile data changed => ", profileData);
      if (!profileData.error && Object.keys(profileData.payload).length) {
        if (profileData?.payload?.error === false) {
          //console.log("Set profile data here");
          // setProfileDetail(profileData?.payload?.data);
          updateLocalStorage(profileData?.payload?.data);
        } else {
          callLogout();
        }
      }
    } catch (err) {
      //console.log("Errror in catch => ", err);
    }
  }, [profileData]);

  useEffect(() => {
    try {
      if (!deleteAccountData.error && Object.keys(deleteAccountData.payload).length) {
        if (deleteAccountData?.payload?.error === false) {
          notification.success({ message: "Success", description: deleteAccountData?.payload?.message, placement: "topRight" });
          callLogout();
        } else {
          notification.error({ message: "Error", description: deleteAccountData?.payload?.message, placement: "topRight" });
        }
      }
    } catch (err) {}
  }, [deleteAccountData]);

  const onDeleteAccount = () => {
    dispatch(deleteAccountRequest());
  };

  return (
    <ModalContainer isOpen={props.open} title={undefined} footer={null}>
      <ModalHeader
        close={() => {
          props.close();
        }}
      />
      {editMode === 1 && <EditProfileForm data={getLocalStorageDataForUser()} close={props?.close} />}
      {editMode === 0 && (
        <>
          {getLocalStorageDataForUser() && <ViewUserProfileData data={getLocalStorageDataForUser} />}
          <div className="text-center mt8 pb8">
            <Button type="primary" onClick={() => setEditMode(1)}>
              <FontAwesomeIcon icon={faPencil} />
              Edit Profile
            </Button>
            <Button type="primary" className="ml4" danger onClick={() => onDeleteAccount()}>
              Delete Account?
            </Button>
          </div>
        </>
      )}
    </ModalContainer>
  );
};

export default ProfileModal;
