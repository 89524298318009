import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, Button, Typography, Image, Checkbox } from "antd";
import GroceryListingSetUpModal from "../../components/GroceryListingSetUpModal";
import NoDataFound from "../../components/CommonWidgets/NoDataFound";
import { checkIfUserLoggedIn, formatTodayDate } from "../../helper";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils, faClock } from "@fortawesome/pro-regular-svg-icons";
import { removeNulls } from "../../modules/objects";
import Colors from "../../theme/Colors";

const { Title, Text } = Typography;

const ViewRecipe = (props) => {
  const location = useLocation();
  if (!location.state) window.location.href = "/saved-recipes";
  const [showGrocerySetUpModal, setShowGrocerySetUpModal] = useState(false),
    [finalDataToShop, setFinalDataToShop] = useState([]),
    [recipeDetail, setRecipeDetail] = useState([]),
    [checkedItems, setCheckedItems] = useState([]),
    [count, setCount] = useState(0),
    handleChange = (e, value) => {
      if (e.target.checked && !checkedItems.includes(e.target.id)) {
        setCheckedItems([...checkedItems, e.target.id]);
      } else {
        setCheckedItems(checkedItems.filter((id) => id !== e.target.id));
      }
    },
    handleGrocerySetUpModalClose = () => {
      setShowGrocerySetUpModal(false);
    },
    handleGrocerySetUpModalShow = async () => {
      let localStorageGroceryData = finalDataToShop?.items, //window.localStorage.getItem("groceryItem"),
        finalData = {};
      finalData.title = formatTodayDate();
      console.log("localStorageGroceryData", localStorageGroceryData);
      finalData.key = Math.random();
      if (localStorageGroceryData) {
        let existingData = finalDataToShop?.items; //JSON.parse(localStorageGroceryData);
        console.log("existingData", existingData, finalDataToShop);
        // let mergeData = [...existingData, ...filterRecipeIngredients()];
        let finalList = filterRecipeIngredients();
        console.log("finalList", finalList);
        const res = finalList.filter((el1) => existingData.some((d2) => d2._id !== el1._id));

        console.log("res", res);
        finalData.items = res;
      } else {
        finalData.items = filterRecipeIngredients();
      }
      // window.localStorage.setItem("groceryItem", JSON.stringify(finalData));
      setFinalDataToShop(finalData);

      setShowGrocerySetUpModal(true);
    },
    resetCheckBoxes = () => {
      setCheckedItems([]);
      setCount(0);
    },
    selectAllCheckBoxes = () => {
      let getBoxes = document.getElementsByClassName("ant-checkbox-input");
      for (var i = 0; i < getBoxes.length; i++) {
        console.log("getBoxes", getBoxes[i].checked);
        if (getBoxes[i].checked !== true) {
          getBoxes[i].checked = true;

          checkedItems.push(getBoxes[i].id);
        }
      }
      setCount(checkedItems.length);
    },
    getPrepTime = (prepTime) => {
      if (prepTime) {
        removeNulls(prepTime);
        return prepTime && typeof prepTime !== "string" ? prepTime[Object.keys(prepTime)] + Object.keys(prepTime) : prepTime;
      } else {
        return "";
      }
    },
    getServing = (serving) => {
      return !serving ? "" : `${serving} serving`;
    },
    getFinalName = (ingItem) => {
      return !ingItem?.amt ? ingItem.name : ingItem?.amt + " " + ingItem.name;
    },
    filterRecipeIngredients = () => {
      let results = recipeDetail.categorizedIngredients && recipeDetail.categorizedIngredients.map((item) => item.ingredients.filter((row) => checkedItems.includes(row._id))),
        subResults = results.filter((row) => row.length > 0),
        newArray = subResults.reduce(function (accumulator, curValue) {
          return accumulator.concat(curValue);
        }, []);
      for (let i = 0; i < newArray.length; i++) {
        newArray[i].key = i;
        newArray[i].quantity = newArray[i].amt;
        newArray[i].unit = !newArray[i]?.unit?.length ? "NA" : newArray[i].unit;
      }
      return newArray;
    };

  useEffect(() => {
    setCount(checkedItems.length);
  }, [checkedItems]);

  useEffect(() => {
    setRecipeDetail(location.state.detail.recipe);
  }, [recipeDetail]);
  console.log("Toggle popup showGrocerySetUpModal", showGrocerySetUpModal);
  return (
    <>
      {!checkIfUserLoggedIn() ? (
        <NoDataFound
          title={"No Saved Recipes Yet"}
          subTitle={
            <>
              <p> Delicious meals are so close - head over to</p>
              <p>
                <a href="https://in.pinterest.com/" className="text-danger" target="_blank" rel="noreferrer">
                  {" "}
                  Pinterest{" "}
                </a>{" "}
                to start saving recipes now!{" "}
              </p>{" "}
            </>
          }
          hasAction={false}
        />
      ) : (
        <>
          <div className="view-recipe-item-meta">
            <Title level={3}> {recipeDetail && recipeDetail?.title}</Title>
          </div>
          <div className="view-recipe-item-meta mb5">
            {recipeDetail && recipeDetail?.prepTime && (
              <div className="receipe-head-tools">
                {recipeDetail && recipeDetail?.prepTime && (
                  <>
                    <FontAwesomeIcon icon={faClock} /> {getPrepTime(recipeDetail?.prepTime)}
                  </>
                )}
                {recipeDetail && recipeDetail?.serveCount && (
                  <>
                    <FontAwesomeIcon icon={faUtensils} className="ml5" /> {getServing(recipeDetail?.serveCount)}
                  </>
                )}
              </div>
            )}
          </div>

          <div className="view-recipe-header-container">
            <div className="view-recipe-header-item v-height">
              <Card className="view-recipe-card">
                <>
                  <div className="view-recipe-body-container">
                    <div className="view-recipe-body-item">
                      <Title level={3}>Ingredients</Title>
                    </div>
                    <div className="view-recipe-body-item text-end">
                      <Button type="primary" className="mr2" onClick={() => selectAllCheckBoxes()}>
                        Select All
                      </Button>
                      <Button type="primary" onClick={() => resetCheckBoxes()}>
                        Clear
                      </Button>
                    </div>
                  </div>
                  <div className="ingredients-container">
                    {recipeDetail.categorizedIngredients &&
                      recipeDetail.categorizedIngredients.map((item, index) => {
                        return (
                          <div key={index}>
                            <Title level={5} className="mb1">
                              {item.category}
                            </Title>
                            <div className="ingredients-list">
                              {item.ingredients &&
                                item.ingredients.map((ingItem, ind) =>
                                  ind / 2 === 0 ? (
                                    <div key={ind} className="split-columns-1">
                                      <Checkbox className="search-recipe checked-recipes" key={ingItem.id} id={ingItem._id} value={ingItem._id} name={ingItem._id} onChange={handleChange} checked={checkedItems.includes(ingItem._id) ? "checked" : false} /> {getFinalName(ingItem)}
                                    </div>
                                  ) : (
                                    <div key={ind} className="split-columns-2">
                                      <Checkbox className="search-recipe checked-recipes" key={ingItem.id} id={ingItem._id} value={ingItem._id} name={ingItem._id} onChange={handleChange} checked={checkedItems.includes(ingItem._id) ? "checked" : false} /> {getFinalName(ingItem)}
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="mt4 text-right">
                    <Button type="primary" disabled={count === 0} onClick={() => handleGrocerySetUpModalShow()}>
                      Add {count} items selected to your grocery list
                    </Button>
                  </div>
                </>
              </Card>
            </div>
            <div className="view-recipe-header-item text-center">
              <div>
                <Image preview={false} alt="product-image" style={{ height: "100%", objectFit: "cover", width: "150px" }} src={recipeDetail?.itemImage} />
              </div>

              <div className="mt4">
                <Button type="primary" size={"large"} danger className="btn-rounded" onClick={() => window.open(recipeDetail?.pinterestUrl)}>
                  View Full Recipe
                </Button>
              </div>

              {/*   <div className="product-box text-center">
                <img className="img-fluid" src={recipeDetail?.itemImage ? recipeDetail?.itemImage : "/img/product/product1.jpg"} alt="Product" />
                  </div> */}
            </div>
          </div>
        </>
      )}
      {showGrocerySetUpModal === true && <GroceryListingSetUpModal open={showGrocerySetUpModal} close={handleGrocerySetUpModalClose} selectedIds={checkedItems} selectedItems={finalDataToShop} recipeDetail={recipeDetail} resetCartItems={() => setFinalDataToShop([])} />}
    </>
  );
};

export default ViewRecipe;
