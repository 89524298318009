import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const forgotPasswordRequest = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: "FORGOT_PASSWORD_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "post",
                url: CONFIG.BACKEND_API_URL + "user/email/verify",
                data,
                auth: 0
            })
            //console.log("FORGOT_PASSWORD response => ", response);
            if(response.error === false) {
                dispatch({type: "FORGOT_PASSWORD_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "FORGOT_PASSWORD_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error in FORGOT_PASSWORD => ", err);
            dispatch({type: "FORGOT_PASSWORD_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}

export const forgotPasswordReset = () => {
    return async (dispatch) => {
        dispatch({type: "FORGOT_PASSWORD_RESET"});
    }
}