import React from "react";
import { ConfigProvider, theme } from "antd";
import Colors from "./Colors";

function ThemeProvider({ children }) {
  const { primaryColor, darkText, mutedText, whiteText, secondaryColor } = Colors;
  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.darkAlgorithm],
        token: {
          colorPrimary: primaryColor,
          colorBgBase: secondaryColor, //"#89CF9D",
          borderRadius: 5,
        },
        components: {
          Menu: {
            colorItemBgSelectedHorizontal: "transparent",
            colorItemBg: darkText,
            colorSubItemBg: darkText,
            colorItemBgSelected: darkText,
            colorItemBgActive: darkText,
            colorItemTextSelectedHorizontal: primaryColor,
          },
          Card: {
            borderRadius: 5,
            borderRadiusLG: 5,
            //  colorItemText: darkText,
            colorText: darkText,
            colorTextDescription: darkText,
            colorTextHeading: darkText,
          },
          List: {
            borderRadiusLG: 5,
            colorItemText: darkText,
            colorText: darkText,
            colorTextDescription: darkText,
            colorTextHeading: darkText,
          },
          Typography: {
            colorLink: primaryColor,
            colorLinkHover: darkText,
            colorLinkActive: primaryColor,
            colorText: darkText,
            colorTextDisabled: mutedText,
            colorTextHeading: darkText,
          },
          Button: {
            colorTextLightSolid: whiteText,
            colorPrimary: primaryColor,
            colorBgContainerDisabled: mutedText,
            colorTextSecondary: primaryColor,
            colorBorderSecondary: primaryColor,
          },
          Select: {
            colorPrimary: primaryColor,
            colorBorder: primaryColor,
            colorText: mutedText,
            colorItemTextHover: primaryColor,
            controlItemBgActive: secondaryColor,
            colorTextPlaceholder: darkText,
          },
          Checkbox: {
            colorPrimary: primaryColor,
            colorBorder: primaryColor,
            colorText: darkText,
            colorItemTextHover: primaryColor,
            controlItemBgActive: secondaryColor,
            colorTextPlaceholder: darkText,
          },
          Result: {
            colorTextHeading: darkText,
            colorTextDescription: darkText,
          },
          Input: {
            colorText: darkText,
            colorTextPlaceholder: mutedText,
            colorTextLabel: darkText,
            colorBorder: primaryColor,
            colorTextDisabled: mutedText,
            colorIcon: darkText,
            colorIconHover: darkText,
          },
          Form: {
            colorText: darkText,
            colorTextPlaceholder: darkText,
            colorTextLabel: darkText,
            colorBorder: primaryColor,
          },
          Modal: {
            colorBgElevated: whiteText,
            colorItemText: darkText,
          },
          Table: {
            colorBorder: primaryColor,
            colorTextHeading: darkText,
            colorText: darkText,
          },
          Popconfirm: {
            colorText: darkText,
          },
          Upload: {
            colorText: darkText,
            colorBorder: darkText,
          },
          Pagination: {
            colorText: darkText,
            colorTextPlaceholder: darkText,
            colorTextLabel: darkText,
            colorBorder: primaryColor,
          },
          Radio: {
            colorText: darkText,
            colorTextPlaceholder: darkText,
            colorTextLabel: darkText,
            colorBorder: primaryColor,
          },
          Steps: {
            colorItemText: darkText,
            colorText: darkText,
            colorFillContent: primaryColor,
            colorFill: primaryColor,
            colorTextDescription: darkText,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}
export default ThemeProvider;
