import { apiCall } from "../../modules/api-call";
import { CONFIG, STORE_CONFIG } from "../../config/config";

export const createGroceryListRequest = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: "CREATE_GROCERY_LIST_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "post",
                url: CONFIG.BACKEND_API_URL + "user/grocery",
                data,
                auth: 1
            })
            //console.log("create grocery response => ", response);
             if(response && response.statusCode === 201 && response.error === false) {
                dispatch({type: "CREATE_GROCERY_LIST_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "CREATE_GROCERY_LIST_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error in groceryListRequest => ", err);
            dispatch({type: "CREATE_GROCERY_LIST_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}


export const createGroceryListReset = () => {
    return async (dispatch) => {
        dispatch({type: "CREATE_GROCERY_LIST_REQUEST_RESET"});
    }
}