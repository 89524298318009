import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { groceryListRequest } from "../../redux/actions/grocery";
import { Card, Spin } from "antd";
import { deleteGroceryListRequest, deleteGroceryListReset } from "../../redux/actions/delete-grocery-list";
import GroceryListItem from "../../components/GroceryListItem";
import NotLoggedIn from "../../components/NotLoggedIn";
import { checkIfUserLoggedIn, formatDateTime } from "../../helper";
import { notification, Typography } from "antd";
import NoDataFound from "../../components/CommonWidgets/NoDataFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-solid-svg-icons";
import SearchTextBlock from "../../components/SearchTextBlock";
import { Animated } from "react-animated-css";
import AntSpinner from "../../components/CommonWidgets/AntSpinner";
import SearchGroceryResults from "../../components/SearchGroceryResults";

import "./index.scss";
import Colors from "../../theme/Colors";
const { Title, Text } = Typography;

const GroceryList = (props) => {
  const [isLoading, setLoading] = useState(true),
    [keyword, setKeyword] = useState(""),
    dispatch = useDispatch(),
    [selectedItem, setSelectedItem] = useState([]),
    [isDeleteLoading, setDeleteLoading] = useState(false),
    { groceryData, deleteGroceryData, createGroceryData } = useSelector((state) => ({ createGroceryData: state.createGroceryData, groceryData: state.groceryData, deleteGroceryData: state.deleteGroceryData })),
    [groceryList, setGroceryList] = useState(groceryData?.payload?.data || []),
    [filterGroceryList, setFilteredGroceryList] = useState(groceryData?.payload?.data || []),
    onFindGroceries = (data = undefined) => {
      //console.log("data => ", data);
      if (checkIfUserLoggedIn()) {
        setLoading(true);
        dispatch(groceryListRequest(data));
      }
    },
    deleteLoading = (loaderValue) => {
      setDeleteLoading(loaderValue);
    },
    deleteGroceryList = (selectedItemId) => {
      setLoading(true);
      dispatch(deleteGroceryListRequest(selectedItemId));
    };

  useEffect(() => {
    if (checkIfUserLoggedIn()) {
      onFindGroceries();
    }
  }, []);

  useEffect(() => {
    //console.log("groceryData state changed => ", groceryData);
    if (!groceryData.error && !groceryData.loading && Object.keys(groceryData?.payload).length) {
      setGroceryList(groceryData?.payload?.data);
      setFilteredGroceryList(groceryData?.payload?.data);
      setSelectedItem(groceryData?.payload?.data[0]);

      setLoading(false);
    } else {
      if (groceryData?.error?.message) {
        //  notification.error({ message: "Error", description: groceryData?.error?.message, placement: "topRight" });
        setGroceryList([]);
        setSelectedItem(null);
        setLoading(false);
      }
    }
  }, [groceryData]);

  useEffect(() => {
    if (!deleteGroceryData.error && Object.keys(deleteGroceryData?.payload).length) {
      //  let filterGroceryData = groceryList.filter((item) => item._id !== selectedItem._id);
      notification.success({ message: "Success", description: deleteGroceryData?.payload?.message, placement: "topRight" });
      /* setGroceryList(filterGroceryData);
      showGrocery(filterGroceryData[0]); */
      setGroceryList(deleteGroceryData?.payload?.data);
      setFilteredGroceryList(deleteGroceryData?.payload?.data);
      setLoading(false);
      dispatch(deleteGroceryListReset());
    } else {
      if (deleteGroceryData?.error?.message) {
        setLoading(false);
        notification.error({ message: "Error", description: deleteGroceryData?.error?.message, placement: "topRight" });
      }
    }
  }, [deleteGroceryData]);

  useEffect(() => {
    if (keyword?.length > 0) {
      let finalData = groceryList
        ?.map((row) => ({
          ...row,
          // items: row.items.filter((subRow) => subRow.name?.toLowerCase().includes(keyword.toLowerCase())),
        }))
        .filter((item) => item.title?.toLowerCase().includes(keyword.toLowerCase()));
      setFilteredGroceryList(finalData);
      if (finalData?.length > 0) {
        setSelectedItem(finalData[0]);
      }
    } else {
      setFilteredGroceryList(groceryList);
      setSelectedItem(groceryList[0]);
    }
  }, [keyword]);

  return (
    <div className="flex flex-column w-full mt8">
      {!checkIfUserLoggedIn() ? (
        <div className="flex flex-column align-items-center justify-content-center flex-1">
          <NotLoggedIn />
        </div>
      ) : (
        <>
          <Typography.Title level={1} className="saved-recipes-title-section" style={{ fontWeight: "bolder" }}>
            <span className="title-part">Grocery&nbsp;</span>
            <div className="title-search-section">
              <span className="title-part" style={{ color: Colors.primaryColor }}>
                Lists
              </span>
            </div>
          </Typography.Title>
          <SearchTextBlock className="mt4" updateKeyword={(value) => setKeyword(value)} placeholderText={"Search grocery lists"} labelText={"Search"} />
          <div className="mt8 ">
            {isLoading ? (
              <div className="flex justify-content-between">
                <Spin indicator={AntSpinner} size={"large"} className={"creator-spin mt8"} />
              </div>
            ) : (
              <SearchGroceryResults loading={isLoading} source="grocery-list" groceryData={filterGroceryList} keyword={keyword} delItem={deleteGroceryList} isDeleteLoading={isDeleteLoading} deleteLoading={deleteLoading} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GroceryList;
