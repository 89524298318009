import React, { useEffect, useState } from "react";
import { Select, Button, Typography } from "antd";
import moment from "moment";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
const { Text } = Typography;

const SelectExistingGroceryListName = (props) => {
  const [selectedGroceryList, setSelectedGroceryList] = useState();
  const onChange = (e) => {
    console.log("selected list", e);
    const getTheLabel = props?.inputData?.filter((row) => row?._id === e.value);
    setSelectedGroceryList({ key: e.value, title: e.label });
    props.selectedRadioGrocerylist({ key: e.value, title: getTheLabel[0]?.title });
  };

  // useEffect(() => {
  //   props.selectedRadioGrocerylist({ key: props?.inputData[0]?.key, title: props?.inputData[0]?.title });
  // }, [props?.inputData]);

  const findOptionsData = () => {
    let optionsData = [];
    let inputValues = props?.inputData;
    for (let i = 0; i < inputValues?.length; i++) {
      optionsData.push({
        value: inputValues[i]._id,
        label: (
          <>
            <div>{inputValues[i].title}</div>
            <div>{moment(inputValues[i]?.createdAt).format("YYYY/MM/DD - hh:mm")}</div>
          </>
        ),
      });
    }
    return optionsData;
  };
  return (
    <div className="shop-text-container">
      <div className="w-full">
        <Select showArrow={true} placeholder="Select a List" labelInValue className="w-full existing-list-items" onSelect={onChange} value={selectedGroceryList} options={findOptionsData()} />
      </div>
      <div>
        <Button
          key="addToExisting"
          type="primary"
          className="mw200 h50"
          disabled={!selectedGroceryList}
          onClick={(e) => {
            props?.manageGrocery("existingGrocery");
            props?.setPage(2);
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Add to List
        </Button>
      </div>
    </div>
  );
};

export default SelectExistingGroceryListName;
