import React, { useState, forwardRef } from "react";
import "./index.scss";
import { Typography, Select, Grid } from "antd";
import Colors from "../../theme/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faSort, faSortAlphaUp, faSortAlphaUpAlt } from "@fortawesome/pro-regular-svg-icons";
const { Title, Text } = Typography;

const SortFilter = forwardRef((props, ref) => {
  let [selectedSortBy, setSelectedSortBy] = useState("Date"),
    recipeList = props?.recipeData;

  return (
    <div className="sort-container">
      <div className="sort-filter-item">
        <Title level={5} className="m0" style={{ color: Colors.mutedText, fontWeight: 500 }}>
          Showing {recipeList?.length} {`recipe${recipeList?.length > 1 ? "s" : ""}`}
        </Title>
      </div>
      <div className="sort-filter-item">
        <Select
          labelInValue
          optionLabelProp="label"
          value={selectedSortBy}
          style={{ width: 180 }}
          onChange={(e) => {
            setSelectedSortBy(e);
            props.updateSortBy(e);
          }}
        >
          <Select.Option
            key={1}
            value="Date"
            label={
              <>
                <FontAwesomeIcon icon={faCalendar} />
                <Typography.Text>Sort by Date</Typography.Text>
              </>
            }
          >
            <>
              <FontAwesomeIcon icon={faCalendar} />
              <Typography.Text>Date</Typography.Text>
            </>
          </Select.Option>
          <Select.Option
            key={2}
            value="Name"
            label={
              <>
                <FontAwesomeIcon icon={faSortAlphaUp} />
                <Typography.Text>Sort by Name</Typography.Text>
              </>
            }
          >
            <>
              <FontAwesomeIcon icon={faSortAlphaUp} />
              <Typography.Text>Name</Typography.Text>
            </>
          </Select.Option>
        </Select>
      </div>
    </div>
  );
});

export default SortFilter;
