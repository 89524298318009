import { apiCall } from "../../modules/api-call";
import { CONFIG, STORE_CONFIG } from "../../config/config";

export const createGroceryList = async (data) => {
        try {
             const { data: response } = await apiCall({
                method: "post",
                url: CONFIG.BACKEND_API_URL + "user/grocery",
                data,
                auth: 1
            })
            //console.log("create grocery response => ", response);
             if(response && response.statusCode === 201 && response.error === false) {
               return response;
            } else {
               return response;
            }
            
        } catch(err) {
            //console.log("error in groceryListRequest => ", err);
            return err;
        }
    
    
}
