import { apiCall } from "../../modules/api-call";
import { CONFIG, STORE_CONFIG } from "../../config/config";

export const updateGroceryList = async (id, data) => {
  try {
    const { data: response } = await apiCall({
      method: "patch",
      url: CONFIG.BACKEND_API_URL + "user/grocery/" + id,
      data,
      auth: 1,
    });
    if (response && response.statusCode === 201 && response.error === false) {
      return response;
    } else {
      return response;
    }
  } catch (err) {
    return err;
  }
};
