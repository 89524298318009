import { CONFIG } from "../config/config";

const checkExtension = (id, src, callback) => {
  let e = new Image();
  e.src = "chrome-extension://" + id + "/" + src; //"chrome-extension://gpmelhlolkdpbbakkbnbmeiellmmeclo/images/gatherd-logo.png";
  console.log(e.src, "chrome-extension://" + id + "/" + src);
  e.onload = () => callback(1);
  e.onerror = () => callback(0);
};

function validateExtension(onValue) {
  checkExtension(CONFIG.GOOGLE_CHROME_EXTENSION_ID, "images/gatherd-logo.png", (ok) => {
    console.log("ok value", ok);
    console.log("gatherd: %s", ok ? "installed" : "not installed");
    window.localStorage.setItem("plugin-status", ok);
    onValue && onValue(ok);
  });
}
export { validateExtension };
