import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const savedRecipesRequest = (data) => {
    return async (dispatch) => {
        try {
            if (!data.sortField) {
                   data.sortField = "date"; 
            }
            dispatch({type: "SAVED_RECIPES_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "post",
                url: CONFIG.BACKEND_API_URL + "user/recipe/search",
                data,
                auth: 1
            })
            //console.log("saved recipes response => ", response);
             if(response && response.statusCode === 200 && response.error === false) {
                dispatch({type: "SAVED_RECIPES_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "SAVED_RECIPES_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error in savedRecipesRequest => ", err);
            dispatch({type: "SAVED_RECIPES_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}

export const recipeDetailRequest = () => {
    return async (dispatch) => {
        try {
            dispatch({type: "RECIPE_DETAIL_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "get",
                url: CONFIG.BACKEND_API_URL + "user/recipe/:id",
                auth: 1
            })
            //console.log("recipe detail response => ", response);
             if(response && response.statusCode === 200 && response.error === false) {
                dispatch({type: "RECIPE_DETAIL_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "RECIPE_DETAIL_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error in Recipe detail Request => ", err);
            dispatch({type: "RECIPE_DETAIL_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}

export const createRecipeRequest = () => {
    return async (dispatch) => {
        try {
            dispatch({type: "CREATE_RECIPE_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "post",
                url: CONFIG.BACKEND_API_URL + "user/recipe",
                auth: 1
            })
            //console.log("recipe detail response => ", response);
             if(response && response.statusCode === 201 && response.error === false) {
                dispatch({type: "CREATE_RECIPE_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "CREATE_RECIPE_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error in Recipe detail Request => ", err);
            dispatch({type: "CREATE_RECIPE_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}

export const savedRecipesReset = () => {
    return async (dispatch) => {
        dispatch({type: "SAVED_RECIPES_REQUEST_RESET"});
    }
}

export const recipeDetailReset = () => {
    return async (dispatch) => {
        dispatch({type: "RECIPE_DETAIL_REQUEST_RESET"});
    }
}

export const createRecipeReset = () => {
    return async (dispatch) => {
        dispatch({type: "CREATE_RECIPE_REQUEST_RESET"});
    }
}