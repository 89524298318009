import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GoogleOAuthProvider , useGoogleLogin , googleLogout } from '@react-oauth/google';
import { socialLoginRequest } from "../../../../redux/actions/social-login";
import { notification } from "antd";
import { checkIfUserLoggedIn, updateLocalStorage } from "../../../../helper";
import SocialButton from "../../../CommonWidgets/SocialButton";
import axios from "axios";
import { CONFIG } from "../../../../config/config";
import { useHistory } from "react-router";
import { handleRedirect } from "../../../../modules/handle-redirect";

export default function GoogleLoginSetUp(props) {
  const dispatch = useDispatch(),
  [user, setUser] = useState([]),
  history = useHistory(),
  [isLoading, setLoading] = useState(false),
    { socialLoginData } = useSelector((state) => ({ socialLoginData: state.socialLoginData })),
    handleGoogleLogin = () => {
        if (!checkIfUserLoggedIn()) {
            startLogin();
        } else {
          handleRedirect(history);
          //  history.push("/saved-recipes");
        }
    },
     startLogin = useGoogleLogin({
        onClick: ()=>setLoading(true),
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    }),
    
    onLogoutSuccess = useCallback(() => {
      googleLogout();
      alert("logout success");
    }, []);

    useEffect(() => {
        if (!socialLoginData.error && Object.keys(socialLoginData?.payload).length) {
          let profileInfo = socialLoginData?.payload?.data.userProfile;
          profileInfo.token = socialLoginData?.payload?.data.token;
          window.localStorage.removeItem(CONFIG.LOCALSTORAGE_USER);
          updateLocalStorage(profileInfo);
          setLoading(false);
           if (props?.pin) {
              window.localStorage.removeItem('pin');
           }
           handleRedirect(history, "redirect");
         // window.location.href = "/saved-recipes";
        } else {
          if (socialLoginData?.error?.message) {
            console.log("abcd", socialLoginData?.error);
            setLoading(false);
            notification.error({ message: "Error", description: socialLoginData?.error?.message, placement: "topRight" });
          }
        }
      }, [socialLoginData]);


  useEffect(
    () => {
        if (user && user?.access_token && !checkIfUserLoggedIn()) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    console.log("google res", res);
                  let data = res.data;
                  let finalObj = {};
                  finalObj.socialId = data?.id;
                  finalObj.source = "google";
                  finalObj.platformToken = user.access_token;
                  finalObj.profileImage = data?.picture;
                  finalObj.firstName = data?.given_name;
                  finalObj.lastName = data?.family_name;
                  finalObj.email = data?.email;
                  dispatch(socialLoginRequest(finalObj));
                })
                .catch((err) => console.log(err));
        }
    },
    [user]
);


  return (
   <SocialButton loading={isLoading} socialIconStyle="google-icon" socialTextStyle="google-text" socialText="Google" socialIcon={""} socialButtonStyle="google-button" onClick={() =>{setLoading(true); handleGoogleLogin();}} />

  );
}

