import { CONFIG, KROGER_APIS } from "../../config/config";

export const createKrogerLogin = async () => {
        try {
            let scope = encodeURIComponent('profile.compact cart.basic:write product.compact');
        window.open(`${KROGER_APIS.KROGER_AUTHORIZE}&scope=${scope}`, 'popup', 'width=600,height=600');
            
        } catch(err) {
            //console.log("error in groceryListRequest => ", err);
            return err;
        }
        
}
