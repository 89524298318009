const resetPassword =  (
    state = {
        loading: false,
        payload: {},
        error: null
    },
    action
) => {
    switch(action.type) {
        case "SET_PASSWORD_REQUEST":
            return { ...state, loading: true, payload: {}, error: null };
        case "SET_PASSWORD_REQUEST_SUCCESS":
            return { ...state, loading: false, payload: action.payload, error: null };
        case "SET_PASSWORD_REQUEST_ERROR":
            return { ...state, loading: false, payload: {}, error: action.payload };
        case "SET_PASSWORD_RESET":
            return { loading: false, payload: {}, error: null };
        default:
            return state;
    }
};

export default resetPassword;