import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Space, Typography, notification } from "antd";
import FloatInput from "../../../CommonWidgets/FloatInput";
import { loginRequest, loginReset } from "../../../../redux/actions/login";
import { updateLocalStorage } from "../../../../helper";
import { CONFIG } from "../../../../config/config";
import { RightArrow } from "../../../CommonWidgets/FontIcons";
import { handleRedirect } from "../../../../modules/handle-redirect";
import { useHistory } from "react-router";
import LabelInput from "../../../CommonWidgets/LabelInput";
import Colors from "../../../../theme/Colors";

const { Title, Text, Link } = Typography;

const EmailLoginForm = (props) => {
  const dispatch = useDispatch(),
    history = useHistory(),
    [form] = Form.useForm(),
    [isLoading, setLoading] = useState(false),
    [hasLoginFormValue, setLoginFormValue] = useState({
      email: "",
      userPassword: "",
    }),
    onChange = (e) => {
      let formData = { ...hasLoginFormValue };
      setLoginFormValue({ ...formData, [e.target.name]: e.target.value });
    },
    { loginData } = useSelector((state) => ({ loginData: state.loginData })),
    onLogin = (data) => {
      setLoading(true);
      if (props.pin) data.pin = props.pin;
      data.user.email = data.user.email.toLowerCase();
      dispatch(loginRequest(data.user));
    };

  useEffect(() => {
    if (!loginData.error && Object.keys(loginData?.payload).length) {
      let profileInfo = loginData?.payload?.data.userProfile;
      profileInfo.token = loginData?.payload?.data.token;
      window.localStorage.removeItem(CONFIG.LOCALSTORAGE_USER);
      updateLocalStorage(profileInfo);
      setLoading(false);
      if (props.pin) {
        window.localStorage.removeItem("pin");
      }
      handleRedirect(history, "redirect");
      //window.location.href = "/saved-recipes";
    } else {
      if (loginData?.error?.message) {
        setLoading(false);
        dispatch(loginReset());
        notification.error({ message: "Error", description: loginData?.error?.message, placement: "topRight" });
      }
    }
  }, [props.pin, loginData]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <Form
      form={form}
      name="normal_login"
      className="pb4"
      initialValues={{
        remember: true,
      }}
      onFinish={onLogin}
    >
      <Space direction="vertical" className="w-full">
        <Form.Item
          name={["user", "email"]}
          rules={[
            {
              type: "email",
              message: "This E-mail address is not valid.",
            },
            {
              required: true,
              message: "Please input your Email",
            },
          ]}
          hasFeedback
        >
          <LabelInput label="Email" type="text" placeholder="johndoe@example.com" required={true} name="email" value={hasLoginFormValue.email} onChange={(e) => onChange(e)} />
        </Form.Item>
        <Form.Item
          className="mb0"
          name={["user", "userPassword"]}
          rules={[
            {
              required: true,
              message: "Please input your Password",
            },
          ]}
          hasFeedback
        >
          <LabelInput
            label={
              <div className="flex w-full align-items-center justify-content-between">
                <Typography.Text style={{ fontSize: "12px", fontWeight: 500 }}>Password</Typography.Text>
                <Link className="ml1" style={{ color: Colors.darkText, fontSize: "12px" }} onClick={() => props.setStep("forgot-password")}>
                  Forgot Password?
                </Link>
              </div>
            }
            type="password"
            placeholder="••••••••"
            required={true}
            name="password"
            value={hasLoginFormValue.userPassword}
            onChange={(e) => onChange(e)}
          />
        </Form.Item>

        <Form.Item shouldUpdate className="mt4">
          {() => (
            <Button type="primary" block htmlType="submit" style={{ borderRadius: "5px", height: "45px" }} loading={isLoading} disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}>
              <span className="f18">Login</span>
            </Button>
          )}
        </Form.Item>
      </Space>
      <Form.Item className="text-center">
        <span>
          <Text>Don’t have an account?</Text>
          <Link className="ml1" onClick={() => props.setStep("register")}>
            Sign up here
          </Link>
        </span>
      </Form.Item>
    </Form>
  );
};
export default EmailLoginForm;
