import React from "react";
import { Image, Grid } from "antd";

const Logo = (props) => {
  const screen = Grid.useBreakpoint();
  const isMobile = !screen.md && !screen.lg && !screen.xl && !screen.xxl;
  return (
    <>
      <Image className="img-fluid" style={props?.style || { width: !isMobile ? "120px" : "unset", marginRight: "50px" }} src={`${process.env.PUBLIC_URL}/img/logo${isMobile && !props?.renderOriginal ? "Mobile" : ""}.png`} alt="Pushtok" preview={false} />
    </>
  );
};
export default Logo;
