import { apiCall } from "../../modules/api-call";
import { CONFIG, STORE_CONFIG } from "../../config/config";

export const createGrabRecipeRequest = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: "GRAB_RECIPE_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "post",
                url: CONFIG.BACKEND_API_URL + "recipe/scrap",
                auth: 1,
                data
            })
            //console.log("GRAB_RECIPE_REQUEST response => ", response);
             if(response && response.statusCode === 201 && response.error === false) {
                dispatch({type: "GRAB_RECIPE_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "GRAB_RECIPE_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error in GRAB_RECIPE_REQUEST => ", err);
            dispatch({type: "GRAB_RECIPE_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}


export const createGrabRecipeReset = () => {
    return async (dispatch) => {
        dispatch({type: "GRAB_RECIPE_REQUEST_RESET"});
    }
}