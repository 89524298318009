import React from "react";
import { Modal } from "antd";
import "../../assets/styles/modal.scss";

const ModalContainer = ({ isOpen, title, children, footer, width, style }) => {
  return (
    <Modal open={isOpen} transitionName="" maskTransitionName="" closable={!!title} mask={true} title={title} centered width={width} footer={footer} bodyStyle={style}>
      {children}
    </Modal>
  );
};

export default ModalContainer;
