import React, { useState, useEffect } from "react";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import { savedRecipesRequest } from "../../redux/actions/recipe";
import SortFilter from "../../components/SortFilter";
import SearchTextBlock from "../../components/SearchTextBlock";
import SearchRecipeResults from "../../components/SearchRecipeResults";
import { checkIfUserLoggedIn } from "../../helper";
import NotLoggedIn from "../../components/NotLoggedIn";
import { createGrabRecipeRequest } from "../../redux/actions/grab-recipe";
import { Spin, Typography } from "antd";
import Colors from "../../theme/Colors";
import AntSpinner from "../../components/CommonWidgets/AntSpinner";

const SavedRecipes = (props) => {
  let url = window.location.href;
  const [recipeList, setRecipeList] = useState(props?.src === "home" ? props.recipeDetail : []),
    [masterRecipeList, setMasterRecipeList] = useState([]),
    [loading, setLoading] = useState(true),
    { recipeData, pinData } = useSelector((state) => ({ recipeData: state.recipeData, pinData: state.pinData })),
    [keyword, setKeyword] = useState(""),
    [sortBy, setSortBy] = useState({ value: "Date" }),
    dispatch = useDispatch(),
    onFindRecipes = (data = {}) => {
      console.log("find recipe data", data);
      setLoading(true);
      if (props.src === "home") {
        data.limit = 4;
      }
      let obj = { searchText: keyword, sortField: sortBy || "date" };
      dispatch(savedRecipesRequest(obj));
    };

  useEffect(() => {
    if (checkIfUserLoggedIn()) {
      onFindRecipes();
    }
  }, [pinData]);

  useEffect(() => {
    if (!recipeData?.error && Object.keys(recipeData?.payload).length) {
      setRecipeList(recipeData?.payload?.data);
      setMasterRecipeList(recipeData?.payload?.data);
      setLoading(false);
    } else {
      setRecipeList([]);
      if (recipeData?.error?.message) setLoading(false);
    }
  }, [recipeData]);

  useEffect(() => {
    if (url.includes("?pin")) {
      const searchParams = new URLSearchParams(window.location.search);
      const pin = searchParams.get("pin");
      console.log(pin);

      dispatch(createGrabRecipeRequest({ pin }));
    }
  }, [dispatch, url]);

  return !checkIfUserLoggedIn() ? (
    <div className="flex flex-column align-items-center justify-content-center flex-1">
      <NotLoggedIn />
    </div>
  ) : (
    <div className="flex flex-column w-full mt8">
      <Typography.Title level={1} className="saved-recipes-title-section" style={{ fontWeight: "bolder" }}>
        <span className="title-part">Saved&nbsp;</span>
        <div className="title-search-section">
          <span className="title-part" style={{ color: Colors.primaryColor }}>
            Recipes
          </span>
        </div>
      </Typography.Title>
      <div className="mt4 flex align-items-center justify-content-center">
        <SearchTextBlock updateKeyword={(value) => setKeyword(value)} placeholderText={"Search saved recipes"} labelText={"Search"} />
      </div>
      <div className="mt4">
        <SortFilter recipeData={recipeList} updateSortBy={(value) => setSortBy(value)} />
      </div>
      <Spin indicator={AntSpinner} spinning={loading} size={"large"} className={"creator-spin mt8"}>
        <SearchRecipeResults loading={loading} keyword={keyword} sortBy={sortBy?.value.toLowerCase()} recipeData={masterRecipeList} />
      </Spin>
    </div>
  );
};

export default SavedRecipes;
