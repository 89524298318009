import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const deleteAccountRequest = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: "DELETE_ACCOUNT_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "delete",
                url: CONFIG.BACKEND_API_URL + "user",
                auth: 1
            })
            //console.log("DELETE PROFILE Request response => ", response);
            if(response && response.statusCode === 200 && response.error === false) {
                dispatch({type: "DELETE_ACCOUNT_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "DELETE_ACCOUNT_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("DELETE_ACCOUNT_REQUEST_ERROR error => ", err);
            dispatch({type: "DELETE_ACCOUNT_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }   
}

export const deleteAccountReset = () => {
    return async (dispatch) => {
        dispatch({type: "DELETE_ACCOUNT_RESET"});
    }
}