import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const socialLoginRequest = (data) => {
    //console.log("social login data", data);
    return async (dispatch) => {
        try {
            dispatch({type: "SOCIAL_LOGIN_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "post",
                url: CONFIG.BACKEND_API_URL + "user/social/login",
                data,
                auth: 0
            })
            //console.log("response => ", response);
            if(response.error === false) {
                dispatch({type: "SOCIAL_LOGIN_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "SOCIAL_LOGIN_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error => ", err);
       
            dispatch({type: "SOCIAL_LOGIN_REQUEST_ERROR", payload: {message: "Invalid Credentials"}});
        }
    }
    
}

export const socialLoginReset = () => {
    return async (dispatch) => {
        dispatch({type: "SOCIAL_LOGIN_REQUEST_RESET"});
    }
}