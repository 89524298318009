import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";
import {checkIfUserLoggedIn} from "../../helper";

export const viewProfileRequest = () => {
    return async (dispatch) => {
        try {
            if (checkIfUserLoggedIn()) {
                dispatch({type: "VIEW_PROFILE_REQUEST", payload: {}});
                const { data: response } = await apiCall({
                    method: "get",
                    url: CONFIG.BACKEND_API_URL + "user",
                    auth: 1
                    
                })
                //console.log("get profile api response => ", response, typeof response);
                if(response && response.statusCode === 200 && response.error === false) {
                    dispatch({type: "VIEW_PROFILE_REQUEST_SUCCESS", payload: response});
                } else {
                    dispatch({type: "VIEW_PROFILE_REQUEST_ERROR", payload: response});
                }
            }   
        } catch(err) {
            //console.log("error in profileRequest => ", err.response);
            dispatch({type: "VIEW_PROFILE_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}

export const editProfileRequest = (profileDetails) => {
    return async (dispatch) => {
        try {
            //console.log("dta", profileDetails);
            dispatch({type: "EDIT_PROFILE_REQUEST", payload: profileDetails});
            const { data: response } = await apiCall({
                method: "patch",
                url: CONFIG.BACKEND_API_URL + "user",
                data: profileDetails,
                auth: 1
            })
            //console.log("EDITProfileRequest response => ", response);
            if(response && response.statusCode === 200 && response.error === false) {
                dispatch({type: "EDIT_PROFILE_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "EDIT_PROFILE_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("EDITProfileRequest error => ", err);
            dispatch({type: "EDIT_PROFILE_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }   
}

export const editProfileReset = () => {
    return async (dispatch) => {
        dispatch({type: "EDIT_PROFILE_RESET"});
    }
}

export const viewProfileReset = () => {
    return async (dispatch) => {
        dispatch({type: "VIEW_PROFILE_REQUEST_RESET"});
    }
}