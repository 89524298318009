import React, { useState, useEffect } from "react";
import { notification, Button, Typography, Image, Checkbox, Grid, Divider, Input, Select, Dropdown, List } from "antd";
import "./index.scss";
import moment from "moment";
import { groceryListRequest } from "../../redux/actions/grocery";
import { createGroceryListRequest, createGroceryListReset } from "../../redux/actions/create-grocery";
import { createGroceryList } from "../../modules/ajax/create-grocery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { removeNulls } from "../../modules/objects";
import ModalHeader from "../../components/CommonWidgets/ModalHeader";
import Colors from "../../theme/Colors";
import { useSelector, useDispatch } from "react-redux";
import { faCircle, faCutlery, faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import GroceryDataTable from "../GroceryDataTable";
import { Animated } from "react-animated-css";
import KrogerShop from "../KrogerShop";
import InstaCartShop from "../InstacartShop";

const { Title } = Typography;

const ShopModal = (props) => {
  const { groceryList } = props;
  const dispatch = useDispatch();
  const screen = Grid.useBreakpoint();
  const isMobile = !screen.md && !screen.lg && !screen.xl && !screen.xxl;
  const [labelStore, selectedLabelStore] = useState("kroger"),
    [krogerConnectStatus, setKrogerConnectStatus] = useState(false),
    storeData = [
      /*  {
        store: "Whole Foods Market",
        image: "/img/store/wholeFoods.png",
        connectStatus: "Not Connected",
        key: "wholefoods"
      },*/
      {
        store: "InstaCart",
        image: "./img/store/instacart.png",
        connectStatus: " NA",
        key: "instacart",
        component: <InstaCartShop />,
      },
      {
        store: "Kroger",
        image: "./img/store/kroger.png",
        connectStatus: krogerConnectStatus,
        key: "kroger",
        component: <KrogerShop updateConnectStatus={(value) => setKrogerConnectStatus(value)} />,
      },
      /* {
       store: "Walmart",
       image: "/img/store/walmart.png",
       connectStatus: "Not Connected",
       key: "walmart"
     }, */
    ];

  return (
    <>
      <div className="flex flex-column">
        {isMobile && (
          <div className="pl4 pr4">
            <ModalHeader
              close={() => {
                props.close();
              }}
            />
          </div>
        )}
        <div className={`flex ${isMobile && "flex-column"}`}>
          {!isMobile && (
            <div className="flex flex-1 align-items-center justify-content-center" style={{ position: "relative", backgroundColor: "#b8dbc2" }}>
              <Image preview={false} alt="product-image" style={{ height: "100%", objectFit: "contain", width: "300px", padding: isMobile ? "5px" : undefined, filter: "brightness(0) invert(1)" }} src={"/img/logo.png"} />
            </div>
          )}
          <div className="flex flex-column flex-1 pr4 pl4">
            {!isMobile && (
              <ModalHeader
                close={() => {
                  props.close();
                }}
              />
            )}

            <Typography.Title level={2} className="mt0 mb1" style={{ fontWeight: "bold" }}>
              Choose your store
            </Typography.Title>

            <div className="mt4 flex flex-column" style={{ height: "500px" }}>
              <Divider className="m0" plain style={{ borderColor: "#c3e6cd" }} />
              <List
                className="mt4"
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 1,
                  lg: 2,
                  xl: 2,
                  xxl: 2,
                }}
                pagination={undefined}
                dataSource={storeData}
                renderItem={(item, index) => {
                  return (
                    <Animated animationIn="fadeIn" isVisible={true} animationInDuration={600} animationInDelay={500 + (index + 1) * 100}>
                      <List.Item key={item.key} className="w-full p0">
                        {/* {JSON.stringify(item)} */}
                        <div className="flex flex-column align-items-center justify-content-between p5" style={{ border: `1px solid ${Colors.primaryColor}`, rowGap: "24px", height: "200px" }}>
                          {item.component}
                        </div>
                      </List.Item>
                    </Animated>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopModal;
