import React from "react";
import { Result, Button, Row } from "antd";
import "./index.scss";

const NoDataFound = (props) => {
  const title = props?.title,
    subTitle = props?.subTitle,
    hasAction = props?.hasAction,
    buttonIcon = props?.buttonIcon,
    buttonText = props?.buttonText;
  return (
    <Row type={"flex"} className="justify-content-center align-items-center">
      <Result
        icon={<img className="img-fluid" src="img/favicon.ico" alt="" />}
        title={title}
        subTitle={subTitle}
        extra={
          hasAction && [
            <Button key="addData" type="primary" onClick={() => props.setAction()}>
              {buttonIcon} {buttonText}
            </Button>,
          ]
        }
      />
    </Row>
  );
};

export default NoDataFound;
