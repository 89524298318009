import { combineReducers } from "redux";
import loginData from "./login";
import signUpData from "./sign-up";
import profileData from "./profile";
import logoutData from "./logout";
import  groceryData from "./grocery";
import  createGroceryData from "./create-grocery";
import  deleteGroceryData from "./delete-grocery-list";
import deleteAccountData from "./delete-account";
import mixSearchData from "./mix-search";
import krogerData from "./kroger-set-up";
import createKrogerKeysData from "./create-kroger-keys";
import getKrogerKeysData from "./get-kroger-keys";
import krogerRefreshToken from "./kroger-refresh-token";
import recipeData from "./recipe";
import pinData from "./grab-recipe";
import createProfileData from "./sign-up";
import editProfileData from "./edit-profile";
import forgotPasswordData from "./forgot-password";
import setPasswordData from "./set-password";
import instaCartData from "./create-instacart-shop";
import krogerShopData from "./create-kroger-shop";
import socialLoginData from "./social-login";
import deleteImageData from "./delete-image";
import fileUploadData from "./file-upload";
import signedUrlData from "./get-signed-url";
import uploadAwsData from "./upload-to-aws";

const rootReducer = (state, action) => {
    // //console.log('root reduser =>', state, action)
    if(action.type === "USER_LOGOUT") {
        state = undefined;
    }
    return appReducer(state, action);
}

const appReducer = combineReducers({
    signUpData,
    loginData,
    socialLoginData,
    profileData,
    mixSearchData,
    krogerData,
    krogerRefreshToken,
    pinData,
    logoutData,
    groceryData,
    createGroceryData,
    deleteGroceryData,
    recipeData,
	deleteAccountData,
    createProfileData,
    editProfileData,
    forgotPasswordData,
    setPasswordData,
    createKrogerKeysData,
    getKrogerKeysData,
    instaCartData,
    krogerShopData,
    deleteImageData,
    fileUploadData,
    uploadAwsData,
    signedUrlData
})

export default rootReducer;