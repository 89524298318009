import React, { useEffect, useState } from "react";
import { getKrogerKeys } from "../../modules/ajax/get-kroger-keys";
import { Card, Radio, Image } from "antd";
import "./index.scss";
import KrogerShop from "../KrogerShop";
import InstaCartShop from "../InstacartShop";
import StoreTile from "../StoreTile";
import StoreHeader from "../StoreHeader";

const ChooseStoreModal = (props) => {
  const [labelStore, selectedLabelStore] = useState("kroger"),
    [currentStep, setCurrentStep] = useState(1),
    [krogerConnectStatus,setKrogerConnectStatus] = useState(false),
   // krogerConnectStatus = getKrogerKeysfromLS() !== null ? "Connected" : "Not Connected",
    changeSelection = (store) => {
      selectedLabelStore(store);
      setCurrentStep(2);
    },
    fetchStoreComponent = () => {
      const chosenStore = storeData.filter((row) => (row.key = labelStore));
      let storeToBe;
      switch (chosenStore[0].key) {
        case "kroger":
          storeToBe = <KrogerShop connectStatus={krogerConnectStatus} updateHeader={() => props.updatePageHeader("YOUR CART")} edit={props?.edit} updateConnectStatus={(value)=>setKrogerConnectStatus(value)} />;
          break;
        case "instacart":
          storeToBe = <InstaCartShop updateHeader={() => props.updatePageHeader("YOUR CART")} edit={props?.edit} />;
          break;
        default:
          break;
      }

      return storeToBe;
    },
    storeData = [
      /*  {
          store: "Whole Foods Market",
          image: "/img/store/wholeFoods.png",
          connectStatus: "Not Connected",
          key: "wholefoods"
        },*/
      {
        store: "InstaCart",
        image: "./img/store/instacart.png",
        connectStatus: " NA",
        key: "instacart",
      },
      {
        store: "Kroger",
        image: "./img/store/kroger.png",
        connectStatus: krogerConnectStatus,
        key: "kroger",
      },
      /* {
         store: "Walmart",
         image: "/img/store/walmart.png",
         connectStatus: "Not Connected",
         key: "walmart"
       }, */
    ];

    useEffect(()=>{
      if (labelStore === "kroger") {
        async function checkKrogerConnectStatus() {
      const isLoggedInResponse = await getKrogerKeys();
        if (isLoggedInResponse?.data?.loginStatus === true) {
          setKrogerConnectStatus("Connected");
        } else {
          setKrogerConnectStatus("Not Connected");
        }
      }
        checkKrogerConnectStatus();
    }
    },[labelStore]);

  return (
    <div className="store-area">
      {currentStep === 1 ? (
        <Radio.Group key={"store-area-select"} className="store-formatter" value={labelStore} onChange={(e) => changeSelection(e.target.value)}>
          {storeData &&
            storeData.map((item, index) => {
              return <StoreTile key={index} storeDetailInLoop={item} selectedStore={labelStore} changeSelection={(key) => changeSelection(key)} edit={props?.edit} />;
            })}
        </Radio.Group>
      ) : (
        storeData
          .filter((items) => items.key === labelStore)
          .map((item, i) => {
            return (
              <>
                <div className="store-list" key={item.key} >
                  <StoreHeader key={i} storeDetailInLoop={item} selectedStore={labelStore} goToPrevStep={()=>setCurrentStep(currentStep-1)} edit={props?.edit} />
                </div>
                <div>{fetchStoreComponent()}</div>
              </>
            );
          })
      )}
    </div>
  );
};

export default ChooseStoreModal;
