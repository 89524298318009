import React, { forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, List, Image, Button, Typography } from "antd";
import { Animated } from "react-animated-css";
import { formatTodayDate } from "../../helper";
import NoDataFound from "../CommonWidgets/NoDataFound";
import "./index.scss";
import { removeNulls } from "../../modules/objects";
import Colors from "../../theme/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faShoppingCart } from "@fortawesome/pro-solid-svg-icons";
import ModalContainer from "../CommonWidgets/ModalContainer";
import ViewRecipeModal from "../ViewRecipeModel";
import CreateGroceryListModal from "../CreateGroceryListModal";

const SearchRecipeResults = forwardRef((props, ref) => {
  const masterRecipeData = props.recipeData,
    [finalDataToShop, setFinalDataToShop] = useState([]),
    [recipeDetail, setRecipeDetail] = useState(),
    [checkedItems, setCheckedItems] = useState([]),
    [showGrocerySetUpModal, setShowGrocerySetUpModal] = useState(false),
    keyword = props?.keyword,
    sortBy = props?.sortBy,
    history = useHistory(),
    getPrepTime = (item) => {
      if (item?.prepTime) removeNulls(item.prepTime);
      return item?.prepTime && typeof item?.prepTime !== "string" ? item?.prepTime[Object.keys(item?.prepTime)] + Object.keys(item?.prepTime) : item?.prepTime;
    },
    [canShowRecipePreview, setCanShowRecipePreview] = useState(),
    redirectToRecipeDetail = (item) => {
      setCanShowRecipePreview(item);
      // history.push({
      //   pathname: "/recipe-detail",
      //   search: "",
      //   state: { detail: { recipeId: item._id, recipe: item } },
      // });
    },
    filterSearchResults = () => {
      let filteredRecipeData = masterRecipeData;
      //  filteredGroceryList = masterGroceryList;
      if (keyword?.length > 0) {
        filteredRecipeData = filteredRecipeData.filter((row) => row?.title.toLowerCase().includes(keyword.toLowerCase() || row?.description.toLowerCase().includes(keyword.toLowerCase())));
        console.log("filteredRecipeData", filteredRecipeData);
      }

      filteredRecipeData = filteredRecipeData.sort((a, b) => {
        if (sortBy === "date") {
          return new Date(b.createdAt) - new Date(a.createdAt);
        } else {
          return a.title.localeCompare(b.title);
        }
      });
      return filteredRecipeData;
    },
    handleGrocerySetUpModalShow = async (detail, itemsChecked) => {
      setRecipeDetail(detail);
      setCheckedItems(itemsChecked);
      let localStorageGroceryData = finalDataToShop?.items, //window.localStorage.getItem("groceryItem"),
        finalData = {};
      finalData.title = formatTodayDate();
      console.log("localStorageGroceryData", localStorageGroceryData);
      finalData.key = Math.random();
      if (localStorageGroceryData) {
        let existingData = finalDataToShop?.items; //JSON.parse(localStorageGroceryData);
        console.log("existingData", existingData, finalDataToShop);
        let finalList = filterRecipeIngredients(detail, itemsChecked);
        console.log("finalList", finalList);
        const res = finalList.filter((el1) => existingData.some((d2) => d2._id !== el1._id));

        console.log("res", res);
        finalData.items = res;
      } else {
        finalData.items = filterRecipeIngredients(detail, itemsChecked);
      }
      // window.localStorage.setItem("groceryItem", JSON.stringify(finalData));
      setFinalDataToShop(finalData);

      setShowGrocerySetUpModal(true);
    },
    filterRecipeIngredients = (detail, itemsChecked) => {
      let results = detail.categorizedIngredients && detail.categorizedIngredients.map((item) => item.ingredients.filter((row) => itemsChecked.includes(row._id))),
        subResults = results.filter((row) => row.length > 0),
        newArray = subResults.reduce(function (accumulator, curValue) {
          return accumulator.concat(curValue);
        }, []);
      for (let i = 0; i < newArray.length; i++) {
        newArray[i].key = i;
        newArray[i].quantity = newArray[i].amt;
        newArray[i].unit = !newArray[i]?.unit?.length ? "NA" : newArray[i].unit;
      }
      return newArray;
    };

  const recipeList = filterSearchResults();

  return (
    !props.loading && (
      <>
        <div>
          {recipeList && recipeList?.length > 0 ? (
            <List
              className="mt5"
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 4,
                xl: 5,
                xxl: 5,
              }}
              dataSource={recipeList}
              pagination={{
                onChange: (page) => {
                  console.log(page);
                },
                pageSize: 15,
                showSizeChanger: false,
              }}
              renderItem={(item, index) => (
                <Animated animationIn="fadeIn" isVisible={true} animationInDuration={600} animationInDelay={500 + (index + 1) * 100}>
                  <List.Item key={index} className="text-center" style={{ backgroundColor: Colors.secondaryColor, padding: "5px", borderRadius: "5px" }}>
                    <Card
                      key={index}
                      style={{ width: "100%" }}
                      className="text-center recipe-results-card"
                      onClick={() => redirectToRecipeDetail(item)}
                      cover={
                        <div className="product-image-wrapper">
                          <Image alt="product-image" style={{ height: "400px", width: "100%", objectFit: "cover" }} preview={false} src={item.itemImage} />
                        </div>
                      }
                    >
                      <div className="flex flex-column justify-content-between">
                        <div className="flex justify-content-between">
                          <Typography.Title level={5} className="m0" style={{ fontSize: "14px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {item?.title}
                          </Typography.Title>
                          <Typography.Text style={{ minWidth: "40px", textAlign: "end" }}>{getPrepTime(item)}</Typography.Text>
                        </div>

                        <div className="mt2 align-self-center w-full">
                          <Button type="primary" className="w-full">
                            <FontAwesomeIcon icon={faPlus} /> Add to List
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </List.Item>
                </Animated>
              )}
            />
          ) : (
            <NoDataFound
              title={"No Saved Recipes Yet"}
              subTitle={
                <>
                  <p> Delicious meals are so close - head over to</p>
                  <p>
                    <a href="https://in.pinterest.com/" className="text-danger" target="_blank" rel="noreferrer">
                      {" "}
                      Pinterest{" "}
                    </a>{" "}
                    to start saving recipes now!{" "}
                  </p>{" "}
                </>
              }
              hasAction={false}
            />
          )}
        </div>
        {!!canShowRecipePreview && (
          <ModalContainer isOpen={!!canShowRecipePreview} title={undefined} footer={null} width={900} style={{ padding: 0 }}>
            <ViewRecipeModal
              recipeId={canShowRecipePreview._id}
              recipe={canShowRecipePreview}
              close={() => setCanShowRecipePreview()}
              onAddToGroceryList={(detail, itemsChecked) => {
                handleGrocerySetUpModalShow(detail, itemsChecked);
                setCanShowRecipePreview(false);
              }}
            />
          </ModalContainer>
        )}
        {showGrocerySetUpModal && (
          <ModalContainer isOpen={showGrocerySetUpModal} title={undefined} footer={null} width={900} style={{ padding: 0 }}>
            <CreateGroceryListModal recipeDetail={recipeDetail} selectedItems={finalDataToShop} open={showGrocerySetUpModal} close={() => setShowGrocerySetUpModal(false)} />
          </ModalContainer>
        )}
      </>
    )
  );
});

export default SearchRecipeResults;
