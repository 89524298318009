import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Space, Card, Typography, notification, Checkbox } from "antd";
import FloatInput from "../CommonWidgets/FloatInput";
import LabelInput from "../CommonWidgets/LabelInput/index";
import { editProfileRequest, editProfileReset } from "../../redux/actions/profile";
import { updateLocalStorage } from "../../helper";
import { RightArrow } from "../CommonWidgets/FontIcons";
import UploadProfilePhoto from "../CommonWidgets/UploadProfilePhoto";

const { Title, Text, Link } = Typography;

const EditProfileForm = (props) => {
  const dispatch = useDispatch(),
    profileDetail = props?.data,
    [form] = Form.useForm(),
    [isLoading, setLoading] = useState(false),
    [hasEditProfileFormValue, setEditProfileFormValue] = useState({
      email: profileDetail?.email,
      // userPassword: profileDetail?.userPassword,
      firstName: profileDetail?.firstName,
      lastName: profileDetail?.lastName,
      zPinCode: profileDetail?.zPinCode,
    }),
    { editProfileData } = useSelector((state) => ({ editProfileData: state.editProfileData })),
    onChange = (e) => {
      let formData = { ...hasEditProfileFormValue };
      setEditProfileFormValue({ ...formData, [e.target.name]: e.target.value });
    },
    onEditProfile = (data) => {
      //console.log('data => ', data);
      setLoading(true);
      dispatch(editProfileRequest(hasEditProfileFormValue));
    };

  useEffect(() => {
    if (!editProfileData.error && Object.keys(editProfileData.payload).length) {
      if (editProfileData?.payload?.error === false) {
        //console.log("editProfileData?.payload?", editProfileData?.payload);
        updateLocalStorage(editProfileData?.payload?.data);
        setLoading(false);
        dispatch(editProfileReset());
        notification.success({ message: "Success", description: editProfileData?.payload?.message, placement: "topRight" });
        props?.close && props?.close();
      } else {
        setLoading(false);
        notification.error({ message: "Error", description: editProfileData?.payload?.message, placement: "topRight" });
      }
    }
  }, [editProfileData]);

  useEffect(() => {
    setEditProfileFormValue({
      email: props?.data?.email,
      firstName: props?.data?.firstName,
      lastName: props?.data?.lastName,
      zPinCode: props?.data?.zPinCode,
    });
  }, [props?.data]);

  return (
    <>
      <div>
        <Title level={3} className="text-center m0 mb4" style={{ fontWeight: "bold" }}>
          Edit Profile
        </Title>
      </div>
      <div className="text-center mb8">
        <UploadProfilePhoto title={"Upload Photo"} imageType={"profile-image"} setSignedUrl={() => {}} onResetSignedUrl={() => {}} />
      </div>
      <Form
        form={form}
        name="normal_signup"
        initialValues={{
          remember: true,
        }}
        className="mt2"
        onFinish={onEditProfile}
      >
        <Space direction="vertical" className="w-full">
          <div className="flex align-items-start justify-content-between" style={{ columnGap: "12px" }}>
            <Form.Item
              className="flex-1"
              name={["user", "firstName"]}
              rules={[
                {
                  required: true,
                  message: "Please input your first name",
                },
              ]}
              initialValue={hasEditProfileFormValue?.firstName}
              hasFeedback
            >
              <LabelInput label="First Name" type="text" placeholder="John" required={true} name="firstName" value={hasEditProfileFormValue.firstName} onChange={(e) => onChange(e)} />
            </Form.Item>
            <Form.Item
              className="flex-1"
              name={["user", "lastName"]}
              rules={[
                {
                  required: true,
                  message: "Please input your last name",
                },
              ]}
              initialValue={hasEditProfileFormValue?.lastName}
              hasFeedback
            >
              <LabelInput label="Last Name" type="text" placeholder="Doe" required={true} name="lastName" value={hasEditProfileFormValue.lastName} onChange={(e) => onChange(e)} />
            </Form.Item>
          </div>
          <Form.Item
            name={["user", "email"]}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail",
              },
              {
                required: true,
                message: "Please input your Email",
              },
            ]}
            initialValue={hasEditProfileFormValue?.email}
            hasFeedback
          >
            <LabelInput label="Email" type="text" placeholder="johndoe@example.com" required={true} name="email" value={hasEditProfileFormValue.email} onChange={(e) => onChange(e)} disabled />
          </Form.Item>
          {/* <Form.Item name={["user", "userPassword"]} hasFeedback>
            <LabelInput label="Password" type="password" placeholder="••••••••" required={true} name="userPassword" value={hasEditProfileFormValue.userPassword} onChange={(e) => onChange(e)} />
          </Form.Item> */}
          <Form.Item
            name={["user", "zPinCode"]}
            rules={[
              {
                required: true,
                message: "Please input your Zip Code",
              },
            ]}
            initialValue={hasEditProfileFormValue?.zPinCode}
            hasFeedback
          >
            <LabelInput label="Zip Code" type="text" placeholder="37069" required={true} name="zPinCode" value={hasEditProfileFormValue.zPinCode} onChange={(e) => onChange(e)} />
          </Form.Item>

          <Form.Item shouldUpdate className="flex justify-content-center mt2 pb4">
            {() => (
              <Button type="primary" htmlType="submit" loading={isLoading} disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length}>
                <span className="f18">Save changes</span>
              </Button>
            )}
          </Form.Item>
        </Space>
      </Form>
    </>
  );
};
export default EditProfileForm;
