// Compact arrays with null entries; delete keys from objects with null value
const removeNulls = function (obj) {
  for (let prop in obj) {
    if (obj[prop] === null || obj[prop] === undefined) {
      delete obj[prop];
    }
  }
};

export { removeNulls };
