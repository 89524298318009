import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const createProfileRequest = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: "CREATE_PROFILE_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "post",
                url: CONFIG.BACKEND_API_URL + "user",
                data,
                auth: 0
            })
            //console.log("createProfileRequest response => ", response);
            if(response && response.statusCode === 201 && response.error === false) {
                dispatch({type: "CREATE_PROFILE_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "CREATE_PROFILE_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("createProfileRequest error => ", err);
            dispatch({type: "CREATE_PROFILE_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }   
}

export const createProfileReset = () => {
    return async (dispatch) => {
        dispatch({type: "CREATE_PROFILE_RESET"});
    }
}

