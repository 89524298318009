import React, { useState, useEffect } from "react";
import GroceryDataTable from "../GroceryDataTable";
import "./index.scss";


const GroceryShoppingModal = (props) => {
  console.log("props in shopping", props);
  const [dataList, setDataList] = useState(props.data),
    setNewData = (newData) => {
      setDataList(newData);
      props.updateNewData(newData);
    };

  useEffect(() => {
    setDataList(props.data);
    props.updateNewData(props.data);
  }, []);

  return (
      props.data && <GroceryDataTable gData={props?.data} updateData={setNewData} />
  
  );
};

export default GroceryShoppingModal;
