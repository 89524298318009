import React, { useState, useEffect } from "react";
import "./index.scss";
import { CONFIG } from "../../config/config";
import { Button, Typography, Card, Steps, Image, Grid } from "antd";
import { checkIfUserLoggedIn } from "../../helper";
import Link from "antd/es/typography/Link";
import LoginModal from "../../components/Auth/Login/LoginModal";
import Colors from "../../theme/Colors";
import { validateExtension } from "../../modules/extension";
const { Title } = Typography;

const Home = (props) => {
  const previousPluginStatus = window.localStorage.getItem("plugin-status");
  const screen = Grid.useBreakpoint();
  const isMobile = !screen.md && !screen.lg && !screen.xl && !screen.xxl,
    [currentStep, setCurrentStep] = useState(0),
    getCurrentStatus = () => {
      if (pluginStatus != 1) {
        setCurrentStep(0);
      } else {
        setCurrentStep(2);
      }
    },
    [showLoginModal, setShowLoginModal] = useState(false);
  const [pluginStatus, setPluginStatus] = useState(previousPluginStatus);

  useEffect(() => {
    getCurrentStatus();
    validateExtension((ok) => {
      setPluginStatus(ok);
    });
  }, []);

  const renderPlatformSteps = () => {
    return (
      <Card className="home-card" bordered style={{ backgroundColor: "#f5f5f5" }}>
        <div className="bg-steps">
          <Steps
            current={currentStep}
            direction="vertical"
            items={[
              {
                title: "Get the Browser Plugin",
                description: (
                  <>
                    {pluginStatus != 1 && (
                      <>
                        {" "}
                        <div>Click the button {isMobile ? "above" : "to the left"} to add the Gather'd plugin to your favorite web browser.</div>
                        {/* <div className="mt2"></div> */}
                      </>
                    )}
                  </>
                ),
              },
              {
                title: "Import Recipes on the Fly",
                description: "While browsing, use the plugin to import recipes from any supported website with ease.",
              },
              {
                title: "Effortless Grocery Lists",
                description: (
                  <>
                    <div>Transform Recipes into well organized grocery lists and fine-tune them as you wish.</div>
                    {checkIfUserLoggedIn() ? (
                      <div className="mt2">
                        <Button
                          className="mt4 align-self-start flex align-items-center"
                          type="primary"
                          style={{ height: "45px", color: "#fff" }}
                          onClick={() => {
                            window.location.href = "/grocery-list";
                          }}
                        >
                          {/* <Image src="img/brand/chromeIcon128.png" preview={false} alt="Chrome Icon" style={{ objectFit: "contain", height: "30px" }} /> */}
                          <Typography.Text className="ml2" style={{ color: Colors.whiteText, fontWeight: "bold", color: "inherit" }}>
                            View Grocery Lists
                          </Typography.Text>
                        </Button>
                      </div>
                    ) : (
                      <Link className="home-link-text" onClick={() => setShowLoginModal(true)}>
                        Login to access your Grocery Lists!
                      </Link>
                    )}
                  </>
                ),
              },
              {
                title: "Choose Your Delivery",
                description: "Pick your preferred grocery store or delivery service and complete your purchase.",
              },
            ]}
          />
        </div>
      </Card>
    );
  };
  return (
    <>
      <div className="flex flex-column w-full mt8">
        <Title level={1} className="page-title-section" style={{ fontWeight: "bolder" }}>
          <span>Discover</span>
          <span style={{ color: Colors.primaryColor }}>Gather'd</span>
        </Title>
        <div className="main-section">
          <div className="left-section">
            <div className="flex flex-column" style={{ maxWidth: "450px" }}>
              <Typography.Text className="page-description" style={{ color: Colors.mutedText }}>
                Transform online recipe inspiration into grocery lists and get them delivered with ease.
              </Typography.Text>
              {pluginStatus != 1 && (
                <Button
                  className="mt4 align-self-start flex align-items-center"
                  type="primary"
                  style={{ height: "60px", paddingLeft: "30px", paddingRight: "30px", color: Colors.whiteText, backgroundColor: Colors.darkText }}
                  onClick={() => {
                    window.location.href = "https://chrome.google.com/webstore/detail/gatherd/" + CONFIG.GOOGLE_CHROME_EXTENSION_ID;
                  }}
                >
                  <Image src="img/brand/chromeIcon128.png" preview={false} alt="Chrome Icon" style={{ objectFit: "contain", height: "30px" }} />
                  <Typography.Text className="ml2" style={{ color: Colors.whiteText, fontWeight: "bold" }}>
                    Download extension from Chrome
                  </Typography.Text>
                </Button>
              )}
              {isMobile && (
                <div className="mt4" style={{ maxWidth: "400px" }}>
                  {renderPlatformSteps()}
                </div>
              )}
              <div className="mt4">
                <Typography.Text className="shop-options-description" style={{ color: Colors.mutedText }}>
                  We work with the following partners to bring your groceries to your doorstep.
                </Typography.Text>
                <div className="mt4 shop-options">
                  <Image className="img-fluid" preview={false} src={"./img/store/kroger.png"} />
                  <Image className="img-fluid" preview={false} src={"./img/store/instacart.png"} />
                </div>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="flex flex-column flex-1 align-items-start justify-content-start">
              <div style={{ maxWidth: "450px" }}>{renderPlatformSteps()}</div>
            </div>
          )}
        </div>
      </div>
      {showLoginModal && <LoginModal open={true} close={() => setShowLoginModal(false)} />}
    </>
  );
};

export default Home;
