import React from "react";
import { Card, Image } from "antd";
import "../StoreTile/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

const StoreHeader = (props) => {
  let  storeDetailInLoop = props?.storeDetailInLoop;
  return (
    <Card
      className={`store-list-item`}
       key={storeDetailInLoop?.key}
     >
      <div className="store-tile-container">
      <div>{<FontAwesomeIcon icon={faArrowLeft} className="cursor-pointer" onClick={()=>props.goToPrevStep()} />}</div>
        <div className="text-center">
          <Image className="img-fluid" preview={false} style={{ width: 100, height: "auto" }} src={storeDetailInLoop?.image} />
        </div>
       
        <div className="forward">{storeDetailInLoop?.connectStatus}</div>
      </div>
    </Card>
  );
};

export default StoreHeader;
