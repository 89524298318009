import React, { useEffect, useState } from "react";
import { Card, List, Popconfirm, Divider, Typography, Button } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faShop, faTrash, faPencil, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import "./index.scss";
import Colors from "../../theme/Colors";

const GroceryListItem = (props) => {
  const selectedItem = props.data;
  const [isHovering, setIsHovering] = useState(false);
  const getFinalName = (ingItem) => {
    return (ingItem.quantity || "") + " " + ingItem.name;
  };
  return (
    <>
      <Card
        bordered={true}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onClick={(e) => (props?.source !== "home" ? props?.openGroceryListSetupModal() : e.preventDefault())}
        actions={
          props?.source !== "home" &&
          [
            // <Popconfirm
            //   onClick={(e) => {
            //     e.stopPropagation();
            //     e.preventDefault();
            //   }}
            //   onCancel={(e) => {
            //     e.stopPropagation();
            //     e.preventDefault();
            //   }}
            //   title="Delete the Grocery List"
            //   description="Are you sure to delete this list?"
            //   icon={<FontAwesomeIcon icon={faTrash} />}
            //   onConfirm={(e) => {
            //     e.stopPropagation();
            //     e.preventDefault();
            //     props.deleteLoading(true);
            //     props.delItem(selectedItem._id);
            //   }}
            //   okText="Yes"
            //   cancelText="No"
            // >
            //   <FontAwesomeIcon icon={faTrash} className={"mr2"} />
            // </Popconfirm>,
            // <FontAwesomeIcon icon={faShop} key="shop" />,
            // <FontAwesomeIcon icon={faPencil} key="edit" onClick={() => props?.openGroceryListSetupModal()} />,
          ]
        }
        title={
          <div className="flex flex-column">
            <div className="flex align-items-center justify-content-between p3">
              <Typography.Text style={{ fontWeight: "bold", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{selectedItem?.title}</Typography.Text>
              <Popconfirm
                placement="topRight"
                overlayClassName="delete-confirm-overlay"
                key="popConfirm"
                title={"Are you sure you want to delete this grocery list? This action can not be undone."}
                onConfirm={() => {
                  props.deleteLoading(true);
                  props.delItem(selectedItem._id);
                }}
                okText="Yes"
                cancelText="No"
                onPopupClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Button
                  type="ghost"
                  className="trash-icon-button-wrapper"
                  style={{ padding: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <FontAwesomeIcon className="m0" style={{ fontSize: "16px" }} icon={faTrash} />
                </Button>
              </Popconfirm>
            </div>
            <Divider className="m0" plain style={{ borderColor: "#c3e6cd" }} />
            <List
              className="p3"
              itemLayout="horizontal"
              style={{ height: "224px" }}
              dataSource={selectedItem.items || []}
              renderItem={(itemRow, index) => (
                <List.Item key={index} style={{ fontWeight: 400, justifyContent: "flex-start" }}>
                  <FontAwesomeIcon icon={faCheckSquare} className="read-only-checkbox" />
                  <Typography.Text style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textTransform: "capitalize" }}>
                    {getFinalName(itemRow)}
                    {/* {itemRow?.quantity?.includes(itemRow?.unit) ? itemRow?.quantity : `${itemRow?.quantity}  ${itemRow?.unit}`} {itemRow?.name} */}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>
        }
        hoverable={props?.source === "home" ? false : true}
        className={"list-item-card"}
      >
        <div className="flex align-items-center justify-content-between">
          <Typography.Text style={{ color: Colors.mutedText, fontSize: "11px", fontWeight: 500 }}>{moment(selectedItem?.createdAt).format("YYYY/MM/DD - hh:mm")}</Typography.Text>
          <Button type="ghost" className="success-icon-button-wrapper flex align-items-center" style={{ padding: 0, fontSize: "16px", color: isHovering ? Colors.primaryColor : "gray", fontWeight: 600 }} onClick={() => props?.openGroceryListSetupModal()}>
            <span>Manage</span> <FontAwesomeIcon className="ml2" icon={faChevronRight} style={{}} />
          </Button>
        </div>
      </Card>
    </>
  );
};
export default GroceryListItem;
