import { apiCall } from "../../modules/api-call";
import { CONFIG, STORE_CONFIG } from "../../config/config";

export const createKrogerKeysRequest = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: "CREATE_KROGER_KEYS_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "post",
                url: CONFIG.BACKEND_API_URL + "user/kroger/code",
                auth: 1,
                data
            })
            //console.log("CREATE_KROGER_KEYS_REQUEST response => ", response);
             if(response && (response.statusCode === 201 || response.statusCode === 200) && response.error === false) {
                dispatch({type: "CREATE_KROGER_KEYS_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "CREATE_KROGER_KEYS_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error in CREATE_KROGER_KEYS_REQUEST => ", err);
            dispatch({type: "CREATE_KROGER_KEYS_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}

export const createKrogerKeysReset = () => {
    return async (dispatch) => {
        dispatch({type: "CREATE_KROGER_KEYS_REQUEST_RESET"});
    }
}