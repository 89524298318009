import { apiCall } from "../../modules/api-call";
import { CONFIG, STORE_CONFIG } from "../../config/config";
import {checkIfUserLoggedIn} from "../../helper";

export const getKrogerKeys = async (data) => {
        try {
            if (checkIfUserLoggedIn()) {
                const { data: response } = await apiCall({
                    method: "get",
                    url: CONFIG.BACKEND_API_URL + "user/kroger/code",
                    auth: 1
                    
                })
            //console.log("create grocery response => ", response);
             if(response && response.statusCode === 200 && response.error === false) {
               return response;
            } else {
               return response;
            }
        }
            
        } catch(err) {
            //console.log("error in groceryListRequest => ", err);
            return err;
        }
    
    
}
