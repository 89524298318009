import React from "react";
import { Result, Typography } from "antd";

const NotLoggedIn = (props) => {
  return (
    <Result
      icon={<img className="img-fluid" src="img/favicon.ico" alt="" />}
      title={
        <Typography.Title level={3} style={{ fontWeight: 800 }}>
          You are not logged in yet!
        </Typography.Title>
      }
      subTitle={<Typography.Text style={{ fontSize: "18px" }}>Login and start creating your grocery list</Typography.Text>}
    />
  );
};

export default NotLoggedIn;
