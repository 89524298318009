import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import "./index.scss";

export default function SocialButton(props) {

return (
    <Button type="block" className={`social-button w-full ${props?.socialButtonStyle}` } onClick={props?.onClick}>
      <div className="social-button-container">
        <div className={`social-button-inner ${!props?.socialIcon ? "google-inner" : ""}`}>
         {props?.socialIcon ? <FontAwesomeIcon icon={props?.socialIcon} className={props?.socialIconStyle} />
       : <div className={props?.socialIconStyle}></div>
}
       </div>
        <div className={`w-full text-center ${props?.socialTextStyle}`}>Continue with {props?.socialText}</div>
      </div>
    </Button>
    
  );
}