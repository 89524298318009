import { apiCall } from "../../modules/api-call";import { CONFIG } from "../../config/config";

export const uploadToAwsRequest = (uploadUrl, data, type = "profile") => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPLOAD_AWS_REQUEST", payload: {} });
      const { data: response } = await apiCall({
        method: "PUT",
        url: uploadUrl,
        data,
        auth: 0,
        headers: {
          "Content-Type": data.type,
        },
      });
      if (!response) {
        dispatch({ type: "UPLOAD_AWS_REQUEST_SUCCESS", payload: { response, [type]: "success" } });
      } else {
        dispatch({ type: "UPLOAD_AWS_REQUEST_ERROR", payload: { response, [type]: "failure" } });
      }
    } catch (err) {
      console.log("UPLOAD_AWS_REQUEST_ERROR error => ", err);
      dispatch({ type: "UPLOAD_AWS_REQUEST_ERROR", payload: {[type]: "failure"  } });
    }
  };
};

export const uploadToAwsReset = () => {
  return async (dispatch) => {
    dispatch({ type: "UPLOAD_AWS_REQUEST_RESET" });
  };
};
