import { apiCall } from "../../modules/api-call";
import { CONFIG } from "../../config/config";

export const deleteGroceryListRequest = (id) => { 
    return async (dispatch) => {
        try {
            dispatch({type: "DELETE_GROCERY_LIST_REQUEST", payload: {}});
            const { data: response } = await apiCall({
                method: "delete",
                url: CONFIG.BACKEND_API_URL + "user/grocery/"+id,
                auth: 1
            })
            //console.log("delete grocery response => ", response);
             if(response && response.statusCode === 200 && response.error === false) {
                dispatch({type: "DELETE_GROCERY_LIST_REQUEST_SUCCESS", payload: response});
            } else {
                dispatch({type: "DELETE_GROCERY_LIST_REQUEST_ERROR", payload: response});
            }
            
        } catch(err) {
            //console.log("error in groceryListRequest => ", err);
            dispatch({type: "DELETE_GROCERY_LIST_REQUEST_ERROR", payload: {message: "Something went wrong"}});
        }
    }
    
}

export const deleteGroceryListReset = () => {
    return async (dispatch) => {
        dispatch({type: "DELETE_GROCERY_LIST_REQUEST_RESET"});
    }
}