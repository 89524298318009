import React, { useState } from "react";
import { Input, DatePicker, Button } from "antd";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import Colors from "../../../theme/Colors";
const { TextArea } = Input;

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false),
  [passwordVisible, setPasswordVisible] = useState(false);
  let { label, values, placeholder, type, required, name, vType, className, disabled, maxLength, icon, onClick, btnType } = props;

  let isOccupied = focus || (values && values.length !== 0);
  let labelClass = isOccupied ? "label label-float" : "label";
  className = className ? className : "";
  let fieldType = type ? type : "text";
  let placeHolderClass = vType === "textarea" ? "placeholder noselect txt-placeholder" : "placeholder noselect";
  labelClass = vType === "date" ? labelClass + " fld-date" : labelClass;
  icon = icon || faSearch;
  return (
    <div className="float-label noselect" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {vType === "textarea" && <TextArea onChange={props.onChange} defaultValue={values} name={name} className={className} />}

       {(type === "text" || type=== "password") && (
        <>
          <Input.Group compact className="flex">
            {type === "password" ? (
                <Input.Password
                 onChange={props.onChange}
                 onPressEnter={() => {
                  onClick && onClick();
                }}
               defaultValue={values}
                name={name}
                visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
              />
            )
           : <Input
              onChange={props.onChange}
              onPressEnter={() => {
                onClick && onClick();
              }}
              type={fieldType}
              defaultValue={values}
              name={name}
              maxLength={maxLength}
              disabled={disabled ? disabled: false}
            /> 
            }
            {vType === "search" && (
              <Button
                type={btnType}
                className="search-btn"
                onClick={() => {
                  onClick && onClick();
                }}
              >
                <FontAwesomeIcon icon={icon} />
              </Button>
            )}
             {vType === "password" && (
               <Button block type="primary" style={{ width: 80, height: 50 }} onClick={() => setPasswordVisible((prevState) => !prevState)}>
               {passwordVisible ? 'Hide' : 'Show'}
             </Button>
            )}
             </Input.Group>
        </>
      )}

      {vType === "date" && <DatePicker disabled={disabled} suffixIcon={null} className={className} onChange={props.onChange} format={"MM/DD/YYYY"} disabledDate={props.disabledDate} defaultValue={values} />}

      {focus && !values && (
        <div className={placeHolderClass} style={{ color: Colors.lightAccentColor }}>
          {placeholder}
        </div>
      )}

      <label className={labelClass} style={{ color: Colors.lightAccentColor }}>
        <span className={"required noselect"}>{required && "* "}</span> {icon && vType === "search" && <FontAwesomeIcon icon={icon} />} {label}
      </label>
    </div>
  );
};

export default FloatInput;
