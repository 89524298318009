import React, { forwardRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, List } from "antd";
import NoDataFound from "../CommonWidgets/NoDataFound";
import { Animated } from "react-animated-css";
import GroceryListItem from "../GroceryListItem";
import GroceryListingSetUpModal from "../GroceryListingSetUpModal";
import EditGroceryListModal from "../EditGroceryListModal";
import ModalContainer from "../CommonWidgets/ModalContainer";
import "./index.scss";
import ShopModal from "../ShopModal";

const SearchGroceryResults = forwardRef((props, ref) => {
  const screen = Grid.useBreakpoint();
  const isMobile = !screen.md && !screen.lg && !screen.xl && !screen.xxl;
  const history = useHistory(),
    keyword = props?.keyword,
    [canShowShop, setCanShowShop] = useState(),
    [showGrocerySettingUpModal, setShowGrocerySettingUpModal] = useState(false),
    [selectedItem, setSelectedItem] = useState(),
    [filterGroceryList, setFilteredGroceryList] = useState(props?.groceryData);
  useEffect(() => {
    if (keyword?.length > 0) {
      let finalData = props?.groceryData
        ?.map((row) => ({
          ...row,
          // items: row.items.filter((subRow) => subRow.name?.toLowerCase().includes(keyword.toLowerCase())),
        }))
        .filter((item) => item.title?.toLowerCase().includes(keyword.toLowerCase()));
      setFilteredGroceryList(finalData);
    } else {
      setFilteredGroceryList(props?.groceryData);
    }
  }, [keyword, props?.groceryData]);

  return !props.loading ? (
    filterGroceryList?.length > 0 ? (
      <>
        <div className={`grocery-list-container align-items-flex-start mt3 ${isMobile ? "" : "p5"}`} style={{ backgroundColor: !isMobile ? "#f5f5f5" : "transparent", borderRadius: "5px" }}>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            pagination={
              props?.source !== "home"
                ? {
                    onChange: (page) => {
                      console.log(page);
                    },
                    pageSize: 8,
                    showSizeChanger: false,
                  }
                : false
            }
            dataSource={props?.source === "home" ? filterGroceryList.slice(0, 4) : filterGroceryList}
            renderItem={(item, index) => {
              return (
                <Animated animationIn="fadeIn" isVisible={true} animationInDuration={600} animationInDelay={500 + (index + 1) * 100}>
                  <List.Item key={item.key} className="w-full p0">
                    <GroceryListItem
                      key={Math.random()}
                      data={item}
                      source={props?.source}
                      loop={index}
                      deleteLoading={(value) => props?.deleteLoading(value)}
                      delItem={props?.delItem}
                      isDeleteLoading={props?.isDeleteLoading}
                      openGroceryListSetupModal={() => {
                        setSelectedItem(item);
                        setShowGrocerySettingUpModal(true);
                      }}
                    />
                  </List.Item>
                </Animated>
              );
            }}
          ></List>
        </div>
        {props?.source === "home" && (
          <div className="mt5 text-center">
            <Button type="primary" onClick={(e) => history.push("/grocery-list")}>
              Load More
            </Button>
          </div>
        )}
        {showGrocerySettingUpModal && (
          <ModalContainer isOpen={showGrocerySettingUpModal} title={undefined} footer={null} width={900} style={{ padding: 0 }}>
            <EditGroceryListModal
              recipeDetail={selectedItem?.recipeDetail}
              selectedItem={selectedItem}
              open={showGrocerySettingUpModal}
              onOpenShop={(listToShop) => {
                setCanShowShop(listToShop);
              }}
              close={() => setShowGrocerySettingUpModal(false)}
            />
          </ModalContainer>
        )}
        {!!canShowShop && (
          <ModalContainer isOpen={!!canShowShop} title={undefined} footer={null} width={900} style={{ padding: 0 }}>
            <ShopModal groceryList={canShowShop} open={!!canShowShop} close={() => setCanShowShop()} />
          </ModalContainer>
        )}
      </>
    ) : (
      <NoDataFound
        title={"No Data found "}
        subTitle={
          <>
            <p> Delicious meals are so close - head over to</p>
            <p>
              <a href="https://in.pinterest.com/" className="text-danger" target="_blank" rel="noreferrer">
                {" "}
                Pinterest{" "}
              </a>{" "}
              to start saving recipes now!{" "}
            </p>{" "}
          </>
        }
        hasAction={false}
      />
    )
  ) : undefined;
});

export default SearchGroceryResults;
