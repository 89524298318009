import React, { useState, useEffect } from "react";
import { notification, Button, Typography, Image, Checkbox, Grid, Divider, Input, Select, Dropdown } from "antd";
import "./index.scss";
import moment from "moment";
import { groceryListRequest } from "../../redux/actions/grocery";
import { createGroceryListRequest, createGroceryListReset } from "../../redux/actions/create-grocery";
import { createGroceryList } from "../../modules/ajax/create-grocery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { removeNulls } from "../../modules/objects";
import ModalHeader from "../../components/CommonWidgets/ModalHeader";
import Colors from "../../theme/Colors";
import { useSelector, useDispatch } from "react-redux";
import { faCutlery, faExternalLink, faTrash } from "@fortawesome/pro-solid-svg-icons";
import GroceryDataTable from "../GroceryDataTable";
import { updateGroceryList } from "../../modules/ajax/update-grocery";

const { Title } = Typography;

const EditGroceryListModal = (props) => {
  const dispatch = useDispatch();
  const screen = Grid.useBreakpoint();
  const isMobile = !screen.md && !screen.lg && !screen.xl && !screen.xxl;
  const [previousListToEdit, setPreviousListToEdit] = useState(props?.selectedItem);
  const [listToEdit, setListToEdit] = useState(props?.selectedItem);

  const saveToDB = (onSuccess) => {
    let finalData = listToEdit;
    console.log("final to db", finalData);
    if (finalData || finalData?.items) {
      if (props?.recipeDetail) {
        finalData.recipeDetail = props?.recipeDetail;
      }

      updateGroceryList(listToEdit._id, finalData)
        .then((res) => {
          if (res?.error === false) {
            window.localStorage.setItem("groceryId", res?.data._id);
            notification.success({ message: "Success", description: res?.message, placement: "topRight" });

            dispatch(groceryListRequest());
            onSuccess && onSuccess();
          } else {
            notification.error({ message: "Success", description: res?.message, placement: "topRight" });
          }
        })
        .catch((err) => {
          notification.error({ message: "Success", description: err?.message, placement: "topRight" });
        });
    }
  };
  useEffect(() => {
    if (previousListToEdit.items.length !== listToEdit.items.length) {
      saveToDB();
    } else {
      let hasChanged = false;
      for (let i = 0; i < previousListToEdit.items.length; i++) {
        const previousItem = previousListToEdit.items[i];
        const currentItem = listToEdit.items[i];
        if (previousItem.name !== currentItem.name || previousItem.quantity !== currentItem.quantity) {
          hasChanged = true;
          break;
        }
      }
      if (hasChanged) {
        saveToDB();
      }
    }
    setPreviousListToEdit(listToEdit);
  }, [listToEdit]);

  return (
    <>
      <div className="flex flex-column">
        {isMobile && (
          <div className="pl4 pr4">
            <ModalHeader
              close={() => {
                props.close();
              }}
            />
          </div>
        )}
        <div className={`flex ${isMobile && "flex-column"}`}>
          {!isMobile && (
            <div className="flex flex-1 align-items-center justify-content-center" style={{ position: "relative", backgroundColor: "#b8dbc2" }}>
              <Image preview={false} alt="product-image" style={{ height: "100%", objectFit: "contain", width: "300px", padding: isMobile ? "5px" : undefined, filter: "brightness(0) invert(1)" }} src={"/img/logo.png"} />
            </div>
          )}
          <div className="flex flex-column flex-1 pr4 pl4">
            {!isMobile && (
              <ModalHeader
                close={() => {
                  props.close();
                }}
              />
            )}

            <Typography.Title level={2} className="mt0 mb1" style={{ fontWeight: "bold" }}>
              {listToEdit.title}
            </Typography.Title>
            <Typography.Text style={{ color: Colors.mutedText }}>{moment(listToEdit.createdAt).format("YYYY/MM/DD - hh:mm")}</Typography.Text>

            <div className="mt4 flex flex-column" style={{ flex: "1 1" }}>
              <Divider className="m0" plain style={{ borderColor: "#c3e6cd" }} />
              <GroceryDataTable
                gData={listToEdit}
                updateData={(newItems) => {
                  console.log("newItems", newItems);
                  setListToEdit({ ...listToEdit, items: newItems });
                }}
              />
              <Divider className="m0" plain style={{ borderColor: "#c3e6cd" }} />
            </div>
            <div className="flex pt5 pb5">
              <div className={`flex flex-1 align-items-center ${listToEdit?.items?.filter((item) => item.selected).length > 0 ? "justify-content-between" : "justify-content-end"}`} style={{ columnGap: "24px" }}>
                {listToEdit?.items?.filter((item) => item.selected).length > 0 && (
                  <div className="trash-icon-button-wrapper">
                    <Typography.Text
                      disabled={listToEdit?.items?.filter((item) => item.selected).length === 0}
                      className="cursor-pointer"
                      style={{ fontWeight: "bold", color: "inherit" }}
                      onClick={() => {
                        setListToEdit({ ...listToEdit, items: listToEdit.items.filter((item) => !item.selected) });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Delete Selected
                    </Typography.Text>
                  </div>
                )}
                <Button
                  block={false}
                  type="primary"
                  style={{ minWidth: "80px" }}
                  onClick={() => {
                    saveToDB(() => {
                      props?.close();
                      props?.onOpenShop(listToEdit);
                    });
                  }}
                >
                  Shop
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGroceryListModal;
