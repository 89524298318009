const deleteGroceryList = (
  state = {
    loading: false,
    payload: {},
    error: null,
  },
  action
) => {
  switch (action.type) {
    case "DELETE_GROCERY_LIST_REQUEST":
      return { ...state, loading: true, payload: {}, error: null };
    case "DELETE_GROCERY_LIST_REQUEST_SUCCESS":
      return { ...state, loading: false, payload: action.payload, error: null };
    case "DELETE_GROCERY_LIST_REQUEST_ERROR":
      return { ...state, loading: false, payload: {}, error: action.payload };
    case "DELETE_GROCERY_LIST_REQUEST_RESET":
      return { loading: false, payload: {}, error: null };
    default:
      return state;
  }
};

export default deleteGroceryList;
