import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Space, Typography, notification, Checkbox } from "antd";
import FloatInput from "../../../CommonWidgets/FloatInput";
import { createProfileRequest } from "../../../../redux/actions/sign-up";
import { updateLocalStorage } from "../../../../helper";
import { RightArrow } from "../../../CommonWidgets/FontIcons";
import "../../Login/LoginModal/index.scss";
import { handleRedirect } from "../../../../modules/handle-redirect";
import { useHistory } from "react-router";
import LabelInput from "../../../CommonWidgets/LabelInput";
import Colors from "../../../../theme/Colors";
const { Text, Link } = Typography;

const RegisterForm = (props) => {
  const dispatch = useDispatch(),
    [form] = Form.useForm(),
    history = useHistory(),
    [isLoading, setLoading] = useState(false),
    [hasRegisterFormValue, setRegisterFormValue] = useState({
      email: "",
      userPassword: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      zPinCode: "",
    }),
    [hasAcceptedTerms, setHasAcceptedTerms] = useState(false),
    onCheckChange = (e) => {
      if (e.target.checked) {
        setHasAcceptedTerms(true);
      } else {
        setHasAcceptedTerms(false);
      }
    },
    onChange = (e) => {
      let formData = { ...hasRegisterFormValue };
      setRegisterFormValue({ ...formData, [e.target.name]: e.target.value });
    },
    { signUpData } = useSelector((state) => ({ signUpData: state.signUpData })),
    onRegister = (data) => {
      setLoading(true);
      delete data["termsconditions"];
      //console.log("data => ", data);
      data.source = "email";
      if (props.pin) data.pin = props.pin;
      data.user.email = data.user.email.toLowerCase();
      dispatch(createProfileRequest({ ...data, ...data.user, user: undefined, confirmPassword: undefined }));
    };

  useEffect(() => {
    //console.log("register  state changed => ", signUpData);
    if (!signUpData?.error && Object.keys(signUpData?.payload).length) {
      let profileInfo = signUpData?.payload?.data.userProfile;
      profileInfo.token = signUpData?.payload?.data.token;
      updateLocalStorage(profileInfo);
      notification.success({ message: "Success", description: signUpData?.payload?.message, placement: "topRight" });
      handleRedirect(history, "redirect");
      //  window.location.href = "/";
      // history.push("/");
    } else {
      //console.log("sdfdsf", signUpData?.error?.message);
      if (signUpData?.error?.message) {
        setLoading(false);
        notification.error({ message: "Error", description: signUpData?.error?.message, placement: "topRight" });
      }
    }
  }, [signUpData]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <Form
        form={form}
        name="normal_signup"
        className="mt2 pb4"
        initialValues={{
          remember: true,
        }}
        onFinish={onRegister}
      >
        <Space direction="vertical" className="w-full" style={{ gap: 0 }}>
          <div className="flex w-full align-items-start justify-content-between">
            <Form.Item
              name={["firstName"]}
              rules={[
                {
                  required: true,
                  message: "Please input your first name",
                },
              ]}
              hasFeedback
            >
              <LabelInput label="First Name" type="text" placeholder="John" required={true} name="firstName" value={hasRegisterFormValue.firstName} onChange={(e) => onChange(e)} />
            </Form.Item>
            <Form.Item
              name={["lastName"]}
              rules={[
                {
                  required: true,
                  message: "Please input your last name",
                },
              ]}
              hasFeedback
            >
              <LabelInput label="Last Name" type="text" placeholder="Doe" required={true} name="lastName" value={hasRegisterFormValue.lastName} onChange={(e) => onChange(e)} />
            </Form.Item>
          </div>

          <Form.Item
            name={["user", "email"]}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail",
              },
              {
                required: true,
                message: "Please input your Email",
              },
            ]}
            hasFeedback
          >
            <LabelInput label="Email" type="text" placeholder="johndoe@example.com" required={true} name="email" value={hasRegisterFormValue.email} onChange={(e) => onChange(e)} />
          </Form.Item>
          <div className="flex align-items-start justify-content-between">
            <Form.Item
              name={["userPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please input your Password",
                },
              ]}
              hasFeedback
            >
              <LabelInput label="Password" type="password" placeholder="••••••••" required={true} name="userPassword" value={hasRegisterFormValue.userPassword} onChange={(e) => onChange(e)} />
            </Form.Item>
            <Form.Item
              name={["confirmPassword"]}
              dependencies={["userPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("userPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match"));
                  },
                }),
              ]}
              hasFeedback
            >
              <LabelInput label="Confirm Password" type="password" placeholder="••••••••" required={true} name="confirmPassword" value={hasRegisterFormValue.confirmPassword} onChange={(e) => onChange(e)} />
            </Form.Item>
          </div>
          <Form.Item
            name={["user", "zPinCode"]}
            rules={[
              {
                required: true,
                message: "Please input your Zip Code",
              },
            ]}
            hasFeedback
          >
            <LabelInput label="Zip Code" type="text" placeholder="37069" required={true} name="zPinCode" value={hasRegisterFormValue.zPinCode} onChange={(e) => onChange(e)} />
          </Form.Item>
          <Form.Item>
            <span className="flex align-items-center">
              <Checkbox onChange={onCheckChange} />
              <Link underline className="ml2" href="https://gatherd.com" rel="noreferrer" target="_blank" style={{ fontSize: "12px" }}>
                I agree with the terms and conditions
              </Link>
            </span>
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button type="primary" block htmlType="submit" loading={isLoading} disabled={!hasAcceptedTerms || !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}>
                <span className="f18">Sign up</span>
              </Button>
            )}
          </Form.Item>
        </Space>
        <Form.Item>
          <div className="text-center text-dark">
            <Text>Already have an account?</Text>
            <Link className="ml1" onClick={() => props.setStep("login")}>
              Login here
            </Link>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
export default RegisterForm;
